<template>
<form class="payment-form" @submit.prevent="purchaseCart">
    <div v-if="!user.shippingAddresses?.[0]" v-once>
        <h3 class="section-title mb-0 mb-4">Shipping Information</h3>
        <div class="row p-0 justify-content-center">
            <label for="info_phone" class="col-11 text-start">Phone Number</label>
            <input  :value="user.phone" @input="saveValue" id="info_phone" name="phone" type="tel" class="col-11 mb-4 margin-bottom-20"
                   placeholder="Phone" maxlength="12" minlength="12" form="pay-form" :required="!user.shippingAddresses?.[0]">
            <span role="alert" class="invalid-feedback"></span>
        </div>
        <div class="row p-0 justify-content-center">
            <label for="info_country" class="col-11 text-start">Country</label>
            <input  :value="user.country" @input="saveValue" id="info_country" name="country" type="text"
                   class="col-11 mb-4 margin-bottom-20" placeholder="Country" :required="!user.shippingAddresses?.[0]">
            <span role="alert" class="invalid-feedback"></span>
        </div>
        <div class="row p-0 justify-content-center">
            <label for="info_city" class="col-11 text-start">City</label>
            <input  :value="user.city" @input="saveValue" id="info_city" name="city" type="text" class="col-11 mb-4 margin-bottom-20"
                   placeholder="City" :required="!user.shippingAddresses?.[0]">
            <span role="alert" class="invalid-feedback"></span>
        </div>
        <div class="row p-0 justify-content-center">
            <label for="info_state" class="col-11 text-start">State</label>
            <input  :value="user.state" @input="saveValue" id="info_state" name="state" type="text" class="col-11 mb-4 margin-bottom-20"
                   placeholder="State" :required="!user.shippingAddresses?.[0]">
            <span role="alert" class="invalid-feedback"></span>
        </div>
        <div class="row p-0 justify-content-center">
            <label for="info_address" class="col-11 text-start">Address</label>
            <input  :value="user.address" @input="saveValue" id="info_address" name="address" type="text"
                   class="col-11 mb-4 margin-bottom-20" placeholder="Address"
                   :required="!user.shippingAddresses?.[0]">
            <span role="alert" class="invalid-feedback"></span>
        </div>
    </div>
    <label v-if="user.shippingAddresses?.[0]" v-once class="col-11 mb-2">Shipping Address</label>
    <div class="form-check mb-3" v-for="({address, city, state, postal_code, country, id}, index) in this.user.shippingAddresses">
        <input class="form-check-input" :value="id" v-model="shippingAddressId" checked type="radio" name="flexRadioDefault">
        <label class="form-check-label">
            {{ address }}, {{ city }}, {{ state }}, {{ postal_code }}, {{ country }}
        </label>
    </div>
    <div>
        <div class="row p-0 justify-content-center mb-4 mt-8">
            <label for="card-element" class="col-11">Card</label>
            <div id="card-element" class="col-11"></div>
        </div>
    </div>

    <button class="dark-btn btn_disabled btn-100 mb-3" :disabled="isProcessing">
        <span v-if="isProcessing" class="spinner-border text-secondary" role="status"></span>
        <span v-else>Buy</span>
    </button>

</form>
</template>



<script>
export default {
    name:'stripe-component',
    props:{
        user:{
            required:true
        },
        isStripe:{
            type:Boolean,
            required: true,

        },
        authCheck:{
            required: true,

        },
        parseCartItems:{
            type:Array,
            required:true
        },
        amount:{
            type:Number,
            required: true
        },
        promoCode:{
            type:String,
            required:false
        }

    },
    data(){
        return {
            isProcessing: false,
            stripe: Stripe(`${process.env.MIX_STRIPE_PUBLISHABLE_API_KEY}`, {
                locale: 'en'
            }),
            stripeCardValidationMessage: null,
            card: null,
            shippingAddressId: this.user?.shippingAddresses?.[0]?.id,
        }
    },

    computed: {
        shippingAddress() {
            if (this.shippingAddressId) {
                return {
                    id: this.shippingAddressId
                }
            }

            return {
                country: this.user.country,
                city: this.user.city,
                state: this.user.state,
                address: this.user.address,
                phone: this.user.phone,
                postal_code: this.user.postal_code
            }
        }
    },

    mounted() {
        $('#info_phone').mask('000-000-0000');
        $('#checkbox').attr('disabled', true);

        if (this.isStripe && this.authCheck) {
            this.setupStripeElements();

        }
    },


    methods:{
        saveValue(e){
            this.$emit('onFormInput', e.target)
        },

        setupStripeElements() {
            this.card = this.stripe.elements().create('card', {
                value: {
                    postalCode: this.user.postal_code
                }
            })

            this.card.on('change', event => {
                if (event.complete) {
                    this.$emit('cardChange', event.value.postalCode)

                }
            });

            this.card.mount('#card-element')
        },

        purchaseCart() {
            if (this.parseCartItems.length<1) {
                return window.notify('Your cart is empty', 'warning')
            }
            if (this.isStripe && !$('#card-element').hasClass('StripeElement--complete')) {
                return window.notify('Your card information is incomplete', 'error')
            }

            this.isProcessing = true;
            let smallCartInfos = [];

            let vm = this;

            let productIds = vm.parseCartItems.map(({product}) => {
                return {
                    id: product.id,
                }
            })

            axios.post('/cart/check/products', productIds)
                .then(response => {
                    this.payWithStripe()
                })
                .catch(error => {
                    window.notify("Sorry, some items have already been purchased, we have already removed them from your cart", "info", 5000);
                    setTimeout(() => {
                        window.location.reload();
                    }, 5050)
                })
        },

        stripeConfirmCardPayment(clientSecret) {
            this.stripe
                .confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: `${this.user.first_name} ${this.user.last_name}`,
                            email: this.user.email
                        }
                    }
                })
                .then(({paymentIntent, error}) => {
                    if (error) throw error

                    this.savePurchase(paymentIntent.id)
                })
                .catch(error => {
                    window.notify(error.message, 'error')
                    this.isProcessing = false
                })
        },

        payWithStripe() {
            axios
                .get('/stripe/intent', {
                    params: {amount: this.amount}
                })
                .then(response => {
                    if (response.data.error?.length > 0) {
                        window.notify(response.data.error, 'warning')
                        return;
                    }
                    this.stripeConfirmCardPayment(response.data.clientSecret)
                })
        },

        savePurchase(stripePaymentId) {
            let vm = this;
            vm.$emit('setSpinner', true);
            let data = {
                promoCode: vm.promoCode,
                amount: vm.amount,
                shippingAddress: vm.shippingAddress,
                paymentId: stripePaymentId,
                paymentMethod: 'Stripe'
            }
            axios.post('/cart/purchase', data)
                .then((response) => {
                    vm.$emit('approved')

                    window.notify(response.data.message);
                })
                .catch(error => {
                    window.notify(error.response.data.message, 'error');
                })
                .finally(() => vm.isProcessing = false)
        },

    }
}

</script>



<style lang="scss">
.payment-form {
label {
    padding: 0;
    margin: 0;
}

input {
    padding: 1px 2px;
}
}
#card-element {
    padding: 14px 2px !important;
    border-bottom: 1px solid #dfdfdf;

&:focus-within {
    border-color: #010101;
}
}
</style>
