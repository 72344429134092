<template>
    <div>
        <div class="col-12 mt-5 z-index-1">
            <div class="wrapper-case">
                <div v-if="products.length>0" class="d-flex flex-wrap justify-content-between">
                    <ProductCard v-for="(product, index) in products" :key="product.id" :product="product" :auth="isAuth" :isFavorite="true" @send="receive"></ProductCard>
                    <div class="flex-grow-1"></div>
                </div>
                <div v-else class="col-12 text-center margin-bottom-60 z-index-1">
                    <h2>
                        You don`t have any favorites yet.
                    </h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import ProductCard from './product-card-catalog.vue';
import {Tooltip} from "bootstrap";

new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
});

export default {
    name: "products",
    components: {
        vSelect,
        Slider,
        ProductCard,
    },
    props: ['isAuth', 'products'],
    methods: {
        receive(object) {
            this.products = this.products.filter((product) => {return product.id != object.id});
        }
    }
}

</script>
<style src="@vueform/slider/themes/default.css">
</style>
<style lang="scss">
.slider-horizontal {
    height: 1px !important;
    margin-bottom: 94px;
}

.slider-connect {
    background: black;
}

.slider-handle {
    background: black;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

.slider-horizontal .slider-handle {
    width: 10px;
    height: 10px;

}

.slider-horizontal .slider-tooltip-top {
    bottom: -50px;
}

.slider-tooltip {
    background-color: transparent;
    border: none;
    color: black;
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
}

.slider-horizontal .slider-tooltip-top:before {
    border: 0.5px solid black;
    width: 38px;
}

.legend {
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    text-transform: uppercase;
    margin-bottom: 25px;
    padding: 0;
}

.checkbox {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.label-text {
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
}

.label-text::before {
    content: "";
    display: inline-flex;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 2px;
    margin-right: 15px;
    /* @include transition(border-color, null, null); */

    .checkbox:focus + &,
    .checkbox:hover + & {
        border-color: black;
    }

    .checkbox:checked + & {
        background-color: black;
        border-color: black;
        background-image: url('/images/icons/check.svg');
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.btn-close-filter {
    position: absolute;
    right: 30px;
    top: 30px;
}

.offcanvas {
    overflow-y: scroll;
    width: 606px !important;
}

.offcanvas-title {
    font-family: montserrat-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 170%;
    text-transform: uppercase;
}

.offcanvas-header {
    padding: 50px 50px 30px 50px;
    @media screen and (max-width: 575px) {
        padding: 30px;
    }
}

.offcanvas-body {
    padding: 0 50px 50px 50px;
    @media screen and (max-width: 575px) {
        padding: 0 30px 30px 30px;
    }
}

.cross {
    width: 14px;
    height: 14px;

    &:hover {
        transform: scale(1.08);
    }
}

.btn-unstyled {
    background-color: transparent;
    border: none;
    border-radius: 100%;

    &:focus {
        box-shadow: none;
        border: none;
    }

    &:hover {
        box-shadow: none;
        border: none;
    }
}

.wrapper-dropdown-filter {
    position: absolute;
    width: 91%;
    top: 45px;

    @media (min-width: 576px) {
        width: 40%;
    }

    @media (min-width: 992px) {
        width: 300px;
        top: 85px;
    }
}

.wrapper-cross-filter {
    position: absolute;
    right: 20px;
    top: 20px;
}

.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    background: #fff;
    border: none;
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    border-radius: 0;
    border-bottom: 1px solid #707070;
    text-transform: lowercase;
    font-variant: small-caps;
    color: black;
}

.style-chooser .vs__dropdown-toggle {
    padding: 9px 0;
}

.style-chooser .vs__dropdown-menu {
    background: #fff;
    top: 30px;
}

.style-chooser .vs__search::placeholder {
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: black;
}

.style-chooser .vs__dropdown-menu {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: black;
    padding: 10px 0px;
    top: 30px;
    width: 100%;
    border: none;
    border-radius: 2px;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
    fill: #757575;
}

.vs__dropdown-option--highlight {
    background-color: #eaecef;
    color: black;
}

.vs__search,
.vs__search:focus {
    margin: 0;
}

.vs__selected {
    margin: 0;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
}

.paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
}

.paginate-buttons:hover {
    background-color: #d8d8d8;
}

.active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
}

.active-page:hover {
    background-color: #2988c8;
}
</style>
