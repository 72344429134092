<template>
  <div class="text-center">
    <div class="slider">
      <div v-for="(product, index) in products" :key="index" class="slide-container">
        <product-card v-if="isLoad" :product="product" :useIn="useIn" :auth="auth">
        </product-card>
      </div>
    </div>
    <PulseLoader v-if="!isLoad" color="#707070" size="25px" />
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import productCard from "./product-card";
export default {
  name: 'slider',
  components: {
    productCard,
    PulseLoader
  },
  props: {
    url: String,
    auth: String,
    useIn: String,
    products: Array,
  },
  data() {
    return {
      isLoad: false,
    }
  },
  mounted() {
    if (this.useIn !== 'similar') {
      window.addEventListener('load', () => {
        this.isLoad = true;
      })
    } else {
      this.isLoad = true;
    }

  }
}
</script>

<style lang="scss" scoped></style>
