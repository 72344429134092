<template>
    <section v-if="similarProducts" class="section flex-column">
        <div class="container-small">
            <h2 class="section-title">You may also like</h2>

        </div>
        <div class="container-slick">
            <div class="row">
                <slider v-if="!isLoading" :products="similarProducts" :use-in="useIn" :auth="auth">
                </slider>
            </div>
        </div>
    </section>
</template>

<script>
import sliderComponent from "./slider-component.vue";
export default {
    name: 'similar-products',
    components:{
        sliderComponent
    },
    props: {
        auth: String,
        useIn:String,
        product:String,
    },
    data(){
        return{
            similarProducts:[],
            isLoading:true
        }
    },
    mounted() {
        const obj = JSON.parse(this.product);
        const vm = this;
        axios.get('/product/get/similar?product_id=' + obj.id)
            .then(function ({data}) {
                vm.similarProducts = data.similarProducts;
                vm.isLoading = false;
            }).catch(err => {
            window.notify(err.response.data.message, 'error');
        });

    }
}
</script>

<style lang="scss" scoped>


</style>
