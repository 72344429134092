<template>
  <div v-if="!onLoading" class="row align-items-center">
    <div class="col-12 d-flex flex-column flex-sm-row justify-content-between align-items-center">
      <div class="d-flex col-12 col-sm-5">
        <div class="d-none d-sm-flex me-2 align-items-center">
          <button class="slick-prev slick-arrow product-slider" aria-label="Prev" data-bs-target="#carouselExampleDark"
            data-bs-slide="prev">Prev
          </button>
        </div>
        <div id="carouselExampleDark" class="carousel carousel-dark slide col-12" data-bs-interval="9000000000"
          data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button v-for="(image, index) in images" :key="index" type="button" data-bs-target="#carouselExampleDark"
              :data-bs-slide-to="index" :class="index === 0 ? 'active' : ''" aria-current="true"
              :aria-label="'Slide' + index"></button>
          </div>
          <div class="carousel-inner">
            <div v-for="(image, index) in images" :key="index"
              :class="index === 0 ? 'carousel-item h-100 active' : 'carousel-item h-100'">
              <a :id="'image-' + index" class="zoom-cursor" :data-caption="apiProduct.name" data-fancybox="1"
                :href="image">
                <img class="d-block w-100 h-100" style="object-fit: contain" :src="image" alt="Image not found" />
              </a>
            </div>
          </div>
        </div>
        <div class="d-none d-sm-flex ms-2 align-items-center">
          <button class="slick-next slick-arrow product-slider" aria-label="Next" data-bs-target="#carouselExampleDark"
            data-bs-slide="next">Next
          </button>
        </div>
      </div>
      <div class="col-12 col-sm-5 p-0">
        <div class="">
          <h2 class="section-title mb-4">{{ apiProduct.name }}</h2>
          <div class="product-description">
            <div v-if="sizes.length" class="d-flex align-items-end">
              <span class="main-text-bold me-3">Size:</span>
              <span v-if="sizes" class="main-text col-7 col-md-9">
                <v-select v-if="sizes.length > 1" :options="sizes" v-model="dropdownSize" class="style-chooser"
                  placeholder="Choose size..." :components="{ Deselect }">
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <img src="/images/icons/arrow.svg" />
                    </span>
                  </template>
                </v-select>
                <span class="text-capitalize" v-else>{{ sizes[0] }}</span>
                <span class="invalid-feedback" :class="{ 'd-block': !isSizeValid }">Please choose a size</span>
              </span>
            </div>
            <div v-if="colors.length" class="d-flex align-items-end">
              <span class="main-text-bold me-2">Color:</span>
              <span v-if="colors" class="main-text col-7 col-md-9">
                <v-select v-if="colors.length > 1" :options="colors" v-model="dropdownColor" class="style-chooser"
                  placeholder="Choose color..." :components="{ Deselect }">
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <img src="/images/icons/arrow.svg" />
                    </span>
                  </template>
                </v-select>
                <span v-else>{{ colors[0] }}</span>
                <span class="invalid-feedback" :class="{ 'd-block': !isColorValid }">Please choose a color</span>
              </span>
            </div>
            <div class="d-flex align-items-end" v-if="materials">
              <span class="main-text-bold me-2">Material:</span>
              <span class="main-text col-7 col-md-9">{{ materials }}</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h3 class="me-2">Price:</h3>
              <div>
                <p class="main-text-bold" v-if="!amountDiscount">
                  <span>$ {{ amount }}</span>
                </p>

                <div class="mb-0 sell-price" v-else>
                  <span class="text-secondary text-decoration-line-through">$ {{ amount }}</span>
                  <h2 class="text-danger m-0">$ {{ amountDiscount }}</h2>
                </div>
              </div>


              <!-- <h2 class="mb-0 main-text">${{ amount }}</h2> -->
            </div>
            <div v-if="quantity >= 1" class="d-flex justify-content-end align-items-center p-0">
              <button @click="decrement" class="small-btn--white counter-wrapper button-text me-3"
                :disabled="number === 1">
                -
              </button>
              <div class="align-items-center justify-content-center">{{ number }}</div>
              <button @click="increment" class="small-btn counter-wrapper button-text ms-3"
                :disabled="number === quantity">
                +
              </button>
            </div>

          </div>
          <p v-if="quantity < 1">Sorry, this product is not available</p>
          <div class="d-flex justify-content-between">
            <button v-if="quantity >= 1" type="button" class="small-btn button-text col-10 " @click="check">Add to
              shopping bag
              <img src="/images/icons/card-white.svg" alt="Shop product" class="margin-left-5" width="24" height="24">
            </button>
            <a href="javascript:void('Like')" data-bs-toggle="tooltip" data-bs-placement="top" title="Like this"
              @click="likeProduct()" class="small-btn--white">
              <img :src="getLikedIcons()" alt="Add to favourite items">
            </a>
          </div>

          <!--                <div class="d-none">{{ apiProduct.product.id ?? '' }} || {{ apiProduct.deleted_at ?? '' }}</div>-->
        </div>
        <shipping-return-policy :policy="getPolicyItems" :title-policy="getPolicyTitle" />
        <size-guide :items="getSizeGuideItems" />
      </div>


    </div>
    <div class="col-12  margin-top-70">
      <h3 class="mb-4 pt-4 bordered-section">Product description:</h3>
      <div class="main-text">
        <div>
          <div v-html="apiProduct.description"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center z-index-1">
    <h2>Loading Product...</h2>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import ShippingReturnPolicy from "./shipping-return-policy.vue";
import SizeGuide from "./size-guide.vue"
import 'vue-select/dist/vue-select.css';

export default {
  name: "product-item",
  components: {
    vSelect,
    ShippingReturnPolicy,
    SizeGuide
  },

  props: ['product', 'policy', 'titlePolicy', 'sizeGuide', "isAuth"],

  data() {
    return {
      Deselect: {
        render: createElement => createElement('span', [
          createElement('img', {
            attrs: {
              src: '/images/icons/cross.svg'
            },
          }),
        ]),
      },
      cloneProduct: {},
      isSizeValid: true,
      isColorValid: true,
      number: 1,
      dropdownSize: null,
      dropdownColor: null,
      onLoading: true,
      amount: 0,
      amountDiscount: null,
      images: [],
      items: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Manage',
          href: '#'
        },
        {
          text: 'Library',
          active: true
        }
      ]
    }
  },

  computed: {

    getPolicyTitle() {
      return JSON.parse(this.titlePolicy)
    },
    getPolicyItems() {
      return JSON.parse(this.policy)
    },
    getSizeGuideItems() {
      return JSON.parse(this.sizeGuide)
    },
    apiProduct() {
      return JSON.parse(this.product);
    },
    productDescription() {
      const descriptionElement = $.parseHTML(this.apiProduct.formatted_description)

      if (descriptionElement.nodeType === Node.TEXT_NODE) {
        return [this.apiProduct.formatted_description]
      }

      return $(descriptionElement)
        .find('.MsoNormal')
        .toArray()
        .slice(2)
        .map(e => e.innerText)
    },
    colors() {
      const response = JSON.parse(this.product);
      if (this.dropdownSize === null) {
        const allColors = response.productInfos?.map((item) => item.color);
        const colors = [...new Set(allColors)];

        return this.filterNullArray(colors);
      } else {
        const items = response.productInfos.filter((item) => item.size === this.dropdownSize);
        const type = typeof (items.color);
        if (type === 'string') {
          var newArray = [items.color];

          return newArray;
        }
        const colors = items?.map((item) => item.color);

        return this.filterNullArray(colors);
      }

    },
    sizes() {
      const response = JSON.parse(this.product);

      if (this.dropdownColor === null) {
        const allSize = response.productInfos?.map((item) => item.size);
        const size = [...new Set(allSize)];

        return this.filterNullArray(size);
      } else {
        const items = response.productInfos.filter((item) => item.color === this.dropdownColor);
        const type = typeof (items.size);
        if (type === 'string') {
          var newArray = [items.size];

          return newArray;
        }
        const size = items?.map((item) => item.size);

        return this.filterNullArray(size);
      }
    },
    quantity() {
      const response = JSON.parse(this.product);

      if (this.dropdownColor === null && this.dropdownSize === null) {
        const items = response.productInfos?.map((item) => item.count);
        const quantity = Math.min.apply(null, items)

        return quantity;
      } else if (this.dropdownColor !== null) {
        const item = response.productInfos.find((item) => item.color === this.dropdownColor);
        return Number(item.count);
      } else if (this.dropdownSize !== null) {
        const item = response.productInfos.find((item) => item.size === this.dropdownSize);
        return Number(item.count);
      } else {
        const item = response.productInfos.find((item) => item.size === this.dropdownSize && item.color === this.dropdownColor);
        return Number(item.count);
      }
    },

    materials() {
      const response = JSON.parse(this.product);
      const allMaterials = response.productInfos?.map((item) => item.material);
      const material = [...new Set(allMaterials)];
      let filterMaterial = this.filterNullArray(material);
      let stringMaterial = filterMaterial.join(', ');

      return stringMaterial === '' ? null : stringMaterial;
    },

    /**
     * Get product variant (info) by size and color.
     * If product has only one variant return it
     */
    userVariant() {
      if (this.apiProduct.productInfos.length === 1) {
        return this.apiProduct.productInfos[0];
      }

      return this.apiProduct.productInfos
        .find(({ size, colour }) => size === this.dropdownSize && colour === this.dropdownColor)
    }
  },
  mounted() {
    this.onLoading = false;
    this.images = this.apiProduct.image_urls;
    this.calculateAmount();
    this.cloneProduct = JSON.parse(this.product);

  },

  methods: {
    likeProduct() {
      if (this.isAuth) {
        this.cloneProduct.like = !this.cloneProduct.like
        axios.post('/action/like/' + this.cloneProduct.id).then(function (response) {
          window.notify(response.data.message)
        })
      } else {
        window.notify('Please login or sign up first to like a product.', 'info')
      }
    },
    getLikedIcons() {
      let liked = "/images/icons/favourite-black.svg"
      let unliked = "/images/icons/favourite.svg"
      if (this.cloneProduct.like) {
        return liked
      }
      return unliked
    },
    increment() {
      let vm = this;
      if (vm.number < vm.quantity) {
        vm.number = vm.number + 1
      }
      vm.calculateAmount();
    },

    /**
     * Filter empty values from array
     * @param array
     * @returns array
     */
    filterNullArray(array) {
      return array.filter(function (el) {
        return el !== null && el !== "" && el !== "null";
      });
    },

    decrement() {
      let vm = this;
      if (vm.number !== 1) {
        vm.number = vm.number - 1
      }
      vm.calculateAmount();
    },
    check() {
      if (this.colors.length > 1 && this.dropdownColor === null) {
        this.isColorValid = false
        return
      } else if (this.colors.length === 1) {
        this.dropdownColor = this.colors[0]
      }

      if (this.sizes.length > 1 && this.dropdownSize === null) {
        this.isSizeValid = false;
        return;
      } else if (this.sizes.length === 1) {
        this.dropdownSize = this.sizes[0]
      }

      let request = {
        product_id: this.apiProduct.id,
        size: this.dropdownSize,
        color: this.dropdownColor,
        quantity: this.number,
        price: this.apiProduct.min_price,
        discount_price: this.apiProduct.discount_price
      };

      window.open(
        `/cart/add?${$.param(request)}`, '_self'
      );
    },
    calculateAmount() {
      let vm = this;
      let minPrice = parseFloat(vm.number * vm.apiProduct.min_price);
      let minPriceDiscount = vm.apiProduct.discount_price ? parseFloat(vm.number * vm.apiProduct.discount_price) : null;

      vm.amount = minPrice < 1000 ? minPrice.toFixed(2) : minPrice.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      if (minPriceDiscount) {
        vm.amountDiscount = minPriceDiscount < 1000 ? minPriceDiscount.toFixed(2) : minPriceDiscount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }

    }
  }

}
</script>
<!-- <style src="simplelightbox/dist/simple-lightbox.min.css"></style> -->
<style lang="scss">
.answer-product {
  width: 32px;
  height: 32px;
  font-size: 16px;
}

.slick-arrow.product-slider {
  position: initial;
}

.bordered-section {
  border-top: 1px solid #ececec;
}

.carousel-indicators {
  bottom: -70px;
}

.carousel {
  height: 500px;
  margin-bottom: 70px;

  @media screen and (min-width: 576px) {
    margin-bottom: 0;
    height: 420px;
  }

  @media screen and (min-width: 992px) {
    height: 500px;
  }
}

.carousel-inner {
  height: 500px;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #ffffff;
  transition: background-color 0.6s ease;

  &.active {
    background-color: black;
  }
}

.carousel-indicators [data-bs-target] {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid black;
  background-clip: padding-box;
  transition: background-color 0.6s ease;
}

.carousel-indicators .active {
  background-color: black;
}

.product-description {
  border-bottom: 1px solid #ECECEC;
  margin-bottom: 20px;

  &>div {
    margin-bottom: 20px;

  }

  &~div {
    margin-bottom: 30px;
  }
}

button[disabled]:not(.fancybox-button) {
  border: 1px solid #DCDCDC;
  background-color: transparent;
  color: #DCDCDC;
}

.vs__search {
  padding: 0 !important;

  &::placeholder {
    color: #DCDCDC;

  }
}

.vs__selected {
  padding: 0;
}

.style-chooser .vs__dropdown-toggle {
  padding: 0;
}

.vs__selected-options {
  padding: 0 !important;
}

.vs__actions {
  padding: 0 0 0 2px !important;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.silentbox-item {
  width: 100%;

  &>img {
    width: 100%;
  }
}

.silentbox-gallery {
  &> :first-child {
    width: 100%;

    &>img {
      width: 100%;
    }
  }

  &> :not(:first-child) {
    width: 50px;

    &>img {
      width: 100%;
    }
  }
}
</style>
