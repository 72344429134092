import { render, staticRenderFns } from "./favorites.vue?vue&type=template&id=2a5b717e&"
import script from "./favorites.vue?vue&type=script&lang=js&"
export * from "./favorites.vue?vue&type=script&lang=js&"
import style0 from "@vueform/slider/themes/default.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./favorites.vue?vue&type=style&index=1&id=2a5b717e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports