<template>
  <div class="">
    <ul class="question-list">
      <li v-for="(item, index) of items" class="question-item p-2 mb-2">
        <div class="question-wrapper">
          <p class="main-text-bold">{{ item.title }}</p>
          <button :id="'showHideContent-' + index + 1" type="button"
            class="btn-open-answer answer-product js-open-content-faq">+</button>
        </div>
        <p :id="'content-' + index + 1" class="main-text gray-text js-content-faq" style="display:none;">{{ item.text }}</p>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  name: 'size-guide',
  props: ['items'],
}

</script>