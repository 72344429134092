<template>

<div class="chip" :class="{clear:clearAll}" tabindex = "-1" @click="clearAllFilters">
    <span class="main-text"><slot></slot></span>
    <button v-if="!clearAll" type = "button" title="Remove chip" aria-label="Remove chip" :key="group" :id="id" @click="clear(id,group)">
        <img src="/images/icons/cross.svg" alt="close">
    </button>
</div>



</template>
<script>
export default {
    name: "chips",
    props:['id', 'group', 'clearAll'],
    methods:{
        clear (id, group) {
            this.$emit('clear', id, group)
        },
        clearAllFilters(){
            if(this.clearAll){
                this.$emit('clearAllFilters')
            }
        }
    }
}
</script>
<style lang="scss">
.chip-group{
    margin-left: 0;
display:flex;
flex-wrap:wrap;
    @media screen and (min-width: 992px){
        margin-left: 310px;
    }
}
.chip.chip-checkbox > .chip-add-icon::after {
content:"add"
}
.chip.chip-checkbox.active > .chip-add-icon::after {
content:"done"
}
.chip > * {
margin-right:2px;
margin-left:2px;
color: black;
}
.chip input {
display: none;
}
.chip.chip-checkbox,
.chip.chip-toggle,
.chip.clickable{
cursor: pointer;
}
.chip.clear{
    border-color:white;
    background-color: black;
    color:white;
    cursor: pointer;
}
.chip.clear > span {
    color:white;
    transition: all 0.3s ease-in-out;
}
.chip.active, .chip.clear.active {
background-color: #d3d2d2;
border-color:black;
box-shadow: 0 1px 1px rgba(0,0,0,0.12)
}
.chip.clear:hover, .chip.clear:focus{
    border-color:black;
    background-color: white;
    & span {
        color:black;
    }
}
.chip:hover, .chip:focus,  {
background-color: #e3e2e2;
}
.chip:active:focus{
background-color: #d3d2d2;
}
.chip button{
border:none;
margin-left: 10px;
margin-top:0;
margin-bottom:0;
padding:0;
background:none;
display:inline-flex;
}
.chip {
transition: all 0.3s ease-in-out;
background-color: #fff;
border-radius: 30px;
border: 1px solid black;
display: inline-flex;
align-items:center;
padding: 6px 12px;
margin-right:6px;
margin-bottom:6px;
}

</style>
