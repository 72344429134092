/**
 * Require
 */
require("./bootstrap");
require("jquery-ui");
require("jquery-validation");
require("jquery-mask-plugin");
const Swal = require("sweetalert2");

/**
 * Notification for front pages
 * Allow type: success | info | warning | error
 * @param {string} text
 * @param {string} type
 * @param {number} timer
 */
window.notify = function (text, type = "success", timer = 3500) {
    Swal.fire({
        position: "top-end",
        icon: type,
        title: text,
        showConfirmButton: false,
        showCloseButton: true,
        timer: timer,
        timerProgressBar: true,
        toast: true,
    });
};
window.error = function (text, type = "error") {
    Swal.fire({
        position: "top-end",
        icon: type,
        title: text,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3500,
        timerProgressBar: true,
        toast: true,
    });
};

/**
 * Vue block
 * @type {Vue | VueConstructor}
 */
import Vue from "vue";
import VueSilentbox from "vue-silentbox";
import PortalVue from "portal-vue";
import loader from "vue-ui-preloader";

Vue.use(loader);
Vue.use(PortalVue);
Vue.use(VueSilentbox);
Vue.component(
    "filter-catalog",
    require("./components/filter-catalog.vue").default
);
Vue.component("favorites", require("./components/favorites.vue").default);
Vue.component("product-item", require("./components/product-item.vue").default);
Vue.component("user-profile", require("./components/user-profile.vue").default);
Vue.component("shop-basket", require("./components/shop-basket.vue").default);
Vue.component(
    "shipping-return-policy",
    require("./components/shipping-return-policy.vue").default
);
Vue.component("size-guide", require("./components/size-guide.vue").default);
Vue.component("product-card", require("./components/product-card.vue").default);
Vue.component("portal-target", require("portal-vue").default);
Vue.component("slider", require("./components/slider-component.vue").default);
Vue.component(
    "similar-products",
    require("./components/similar-products-component.vue").default
);
Vue.component("purchases-item", require("./components/purchases-item").default);

/**
 * Custom global js files
 */
require("./helpers/animation-product-card");
require("./helpers/confirmation");

if ($("#app").length) {
    const app = new Vue({
        el: "#app",
    });
}
