<template>
  <div>
    <h2 class="text-center">{{ title }}</h2>
    <div class="row align-items-end">

      <div class="col-12 col-sm-3 col-md-2 me-md-2 d-lg-none z-index-3 position-relative">
        <div class="wrapper-big wrapper-filter">
          <a class="dark-btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" role="button"
            aria-controls="offcanvasRight">
            Filter
          </a>
        </div>
        <!--Filters form for mobile-->
        <div class="offcanvas offcanvas-end d-lg-none" tabindex="-1" id="offcanvasRight" data-bs-scroll="true"
          data-bs-backdrop="true" aria-labelledby=" offcanvasExampleLabel">
          <button type="button" class="btn-close btn-close-filter text-reset" data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
          <div class="offcanvas-header">
            <h2 class="offcanvas-title" id="offcanvasExampleLabel">Filter</h2>
          </div>
          <div class="offcanvas-body">
            <div>
              <div style="width: 100%">
                <div class="accordion accordion-flush" style="width: 100%">
                  <div class="accordion-item" v-for="(item, i) in Object.keys(filters)" :key="i" :value="item"
                    v-if="item !== 'priceMin' && item !== 'priceMax'">
                    <fieldset>
                      <h2 class="accordion-header" :id="'heading' + item">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          :data-bs-target="'#' + item" aria-expanded="false" :aria-controls="item">
                          <legend class="legend-filter">{{ item }}</legend>
                        </button>
                      </h2>
                      <div :id="item" class="accordion-collapse collapse" :aria-labelledby="'heading' + item">
                        <div class="accordion-body" v-if="item === 'categories'">
                          <div v-if="filterCategories?.length">
                            <div class="accordion accordion-flush" style="width: 100%">
                              <div v-for="({ name, slug, childCategories, id }) in categories" :key="id"
                                class="accordion-item">
                                <template v-if="name !== 'New'">
                                  <h2 class="accordion-header" :id="'heading' + name">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                      :data-bs-target="'#' + name" aria-expanded="false" :aria-controls="name"
                                      @click="clickOnLink($event, '/catalog/' + slug, 'categories')">
                                      <span class="main-text navigation_item py-1"
                                        :class="chouseCategory.slice(-2).includes(slug) ? ['text-decoration-underline', 'active'] : []">
                                        <a :href="'/catalog/' + slug">{{ name }}</a>
                                      </span>
                                    </button>
                                  </h2>
                                  <div :id="name" class="accordion-collapse collapse" :aria-labelledby="'heading' + name">
                                    <div class="accordion-body d-flex flex-column pt-0" v-if="childCategories?.length">
                                      <span class="main-text navigation_item py-1"
                                        v-for="({ name, slug: childSlug, id, is_title }) in childCategories"
                                        :class="chouseCategory.at(-1) === childSlug ? ['text-decoration-underline', 'active'] : []"
                                        :key="id">
                                        <a v-if="!is_title" :href="'/catalog/' + slug + '/' + childSlug">{{ name }}</a>
                                        <p class="fw-bold fs-6 text-decoration-underline" v-else>{{ name }}</p>
                                      </span>
                                    </div>
                                    <div v-else class="col-12 main-text">No available category</div>
                                  </div>
                                </template>
                                <template v-else>
                                  <button class="category-no-subcategory" type="button"
                                    @click="clickOnLink($event, '/catalog/' + slug, 'categories')">
                                    <span class="main-text navigation_item py-1"
                                      :class="chouseCategory.slice(-2).includes(slug) ? ['text-decoration-underline', 'active'] : []">
                                      <a :href="'/catalog/' + slug">{{ name }}</a>
                                    </span>
                                  </button>
                                </template>
                              </div>
                            </div>
                          </div>
                          <div v-else class="col-12 main-text">No available category</div>
                        </div>
                        <div class="accordion-body" v-if="item === 'brands'">
                          <div v-if="filters.brands?.length !== 0" class="col-12">
                            <label v-for="(brand, index) in sortInAlphabet(filters.brands)" :key="brand"
                              class="form-check-label col-12 p-0" :for="brand">
                              <input type="checkbox" name="color" :value="brand" class="checkbox" :id="brand"
                                :checked="checkedBrands.indexOf(brand) !== -1 && true"
                                @change="sendReqwest($event, 'brands')" />
                              <span class="label-text d-flex align-items-center">{{ brand }}</span>
                            </label>
                          </div>
                          <div v-else class="col-12 main-text">No available brands</div>
                        </div>
                        <div class="accordion-body" v-if="item === 'colors'">
                          <div v-if="filters.colors?.length !== 0" class="col-12">
                            <label v-for="(color, index) in sortInAlphabet(filters.colors)" :key="color"
                              class="form-check-label col-12 p-0" :for="color">
                              <input type="checkbox" name="color" :value="color" class="checkbox" :id="color"
                                :checked="checkedColors.indexOf(color) !== -1 && true"
                                @change="sendReqwest($event, 'colors')" />
                              <span class="label-text d-flex align-items-center">{{ color }}</span>
                            </label>
                          </div>
                          <div v-else class="col-12 main-text">No available colors</div>
                        </div>
                        <div class="accordion-body" v-if="item === 'sizes'">
                          <div v-if="filters.sizes?.length !== 0" class="col-12">
                            <label v-for="(size, index) in filters.sizes" :key="index" class="form-check-label col-6 p-0"
                              :for="size">
                              <input type="checkbox" name="size" :value="size" class="checkbox" :id="size"
                                :checked="checkedSizes.indexOf(size) !== -1 && true"
                                @change="sendReqwest($event, 'sizes')" />
                              <span class="label-text d-flex align-items-center">{{ size }}</span>
                            </label>
                          </div>
                          <div v-else class="col-12 main-text">No available sizes</div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="accordion accordion-flush" id="prices">
                  <div class="accordion-item">
                    <form @submit.prevent="applyFilter" style="width: 100%">
                      <fieldset class="margin-bottom-40">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#price" aria-expanded="false" aria-controls="prices">
                            <legend class="legend-filter">Price</legend>
                          </button>
                        </h2>
                        <div id="price" class="accordion-collapse collapse " aria-labelledby="headingOne"
                          data-bs-parent="#prices">
                          <div class="accordion-body">
                            <Slider v-model="value" :min="priceMin" :max="priceMax" />
                            <div class="col-5 p-0 ms-auto">
                              <button type="submit" class="dark-btn  btn-100">
                                Ok
                              </button>
                            </div>
                          </div>

                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div class="row col-12 justify-content-between m-0">
                  <div class="col-12 p-0">
                    <button type="reset" class="light-btn btn-100" @click="onClear">Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End   Filters form for mobile            -->
      <div class="col-12 col-sm-4 col-lg-3 mt-3 mt-lg-0 z-index-2 sort-select">
        <v-select :options="options" v-model="dropdown" :clearable="true" class="style-chooser" placeholder="Sort by..."
          @input="applyFilter" :components="{ Deselect }">
          <template #open-indicator="{ attributes }" #val>
            <span v-bind="attributes">
              <img src="/images/icons/arrow.svg" alt="&#8595;" />
            </span>
          </template>
        </v-select>
      </div>

      <div class="col-12 mt-5" v-if="isProductsFound">
        <h2 v-if="search">Results for <b>&laquo;{{ search }}&raquo;</b></h2>
      </div>

      <div v-else class="col-12 mt-5">
        <h3 v-if="!search" class="text-center">
          We didn't find anything, try changing your filters or category
        </h3>
        <h3 v-else class="text-center">
          We didn't find anything for <b>&laquo;{{ search }}&raquo;</b>, try another search
        </h3>
        <h3 class="section-title text-center">You may also like</h3>
      </div>
      <!--            <div class="col-12 mt-5 z-index-1">-->
      <div id="filterChipsContainer" class="col-12 chip-group mb-2" style="display:inline-flex;">
        <Chips @clearAllFilters='deleteAllChip' :clearAll="true"
          v-if="checkedColors.length || checkedSizes.length || checkedBrands.length || value[0] !== priceMin || value[1] !== priceMax + 1">
          Clear all</Chips>
        <div v-for="{ name, parent_id, slug, id } in filterCategories" :key="id"
          style="width: 0;height: 0; overflow: hidden">
          <Chips @clear='deleteChip' v-if="parent_id === null && chouseCategory.indexOf(slug) >= 1" :id="slug">{{ name }}
          </Chips>
          <Chips @clear='deleteChip' v-else-if="parent_id !== null && chouseCategory.indexOf(slug) >= 1" :id="slug">{{
            name }}
          </Chips>
        </div>
        <Chips @clear='deleteChip' v-for="color in checkedColors" :key="color" group="colors" :id="color">{{ color }}
        </Chips>
        <Chips @clear='deleteChip' v-for="size in checkedSizes" :key="size" group="sizes" :id="size">{{ size }}</Chips>
        <Chips @clear='deleteChip' v-for="brand in checkedBrands" :key="brand" group="brands" :id="brand">{{ brand }}
        </Chips>
        <Chips @clear='deleteChip' v-if="value[0] !== priceMin || value[1] !== priceMax + 1" group="price">{{
          value[0] + '-' + value[1] }}</Chips>
      </div>
      <div class="d-flex z-index-1" id="filter-wrapper">
        <!--  Filters form for desktop-->
        <div class="d-none d-lg-block filters-desktop js-sticky-container">
          <div id="filter-container">
            <div class="sidebar__inner filter-sidebar">
              <div class="accordion accordion-flush" style="width: 100%">
                <div class="accordion-item" v-for="(item, i) in Object.keys(filters)" :key="i" :value="item"
                  v-if="item !== 'priceMin' && item !== 'priceMax'">
                  <fieldset>
                    <h2 class="accordion-header" :id="'heading' + item">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="'#' + item" aria-expanded="false" :aria-controls="item"
                        >
                        <legend class="legend-filter">{{ item }}</legend>
                      </button>
                    </h2>
                    <div :id="item" class="accordion-collapse collapse" :aria-labelledby="'heading' + item"
                      :class="{ 'mt-56': item !== 'categories' }">
                      <!-- Category -->
                      <div class="accordion-body pt-0" v-if="item === 'categories'">
                        <div v-if="filterCategories?.length">
                          <div class="accordion accordion-flush" id="categories" style="width: 100%">
                            <div v-for="({ name, slug, childCategories, id }) in categories" :key="id"
                              class="accordion-item">
                              <template v-if="name !== 'New'">
                                <h2 class="accordion-header" :id="'heading' + name">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    :data-bs-target="'#' + name" aria-expanded="false" :aria-controls="name"
                                    @click="clickOnLink($event, '/catalog/' + slug, 'categories')">
                                    <span class="main-text navigation_item py-1"
                                      :class="chouseCategory.slice(-2).includes(slug) ? ['text-decoration-underline', 'active'] : []">
                                      <a :href="'/catalog/' + slug">{{ name }}</a>
                                    </span>
                                  </button>
                                </h2>
                                <div :id="name" class="accordion-collapse collapse" :aria-labelledby="'heading' + name"
                                  data-bs-parent="#categories">
                                  <div class="accordion-body d-flex flex-column pt-0" v-if="childCategories?.length">
                                    <span class="main-text navigation_item py-1"
                                      v-for="({ name, slug: childSlug, id, is_title }) in childCategories"
                                      :class="chouseCategory.at(-1) === childSlug ? ['text-decoration-underline', 'active'] : []"
                                      :key="id">
                                      <a v-if="!is_title" :href="'/catalog/' + slug + '/' + childSlug">{{ name }}</a>
                                      <p class="fw-bold fs-6 text-decoration-underline" v-else>{{ name }}</p>
                                    </span>
                                  </div>
                                  <!-- <div v-else class="col-12 main-text">No available category</div> -->
                                </div>
                              </template>
                              <template v-else>
                                <button class="category-no-subcategory" type="button"
                                  @click="clickOnLink($event, '/catalog/' + slug, 'categories')">
                                  <span class="main-text navigation_item py-1"
                                    :class="chouseCategory.slice(-2).includes(slug) ? ['text-decoration-underline', 'active'] : []">
                                    <a :href="'/catalog/' + slug">{{ name }}</a>
                                  </span>
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                        <div v-else class="col-12 main-text">No available category</div>
                      </div>
                      <!-- /Category -->
                      <!-- Brands -->
                      <div class="accordion-body" v-if="item === 'brands'">
                        <div v-if="filters.brands?.length !== 0" class="col-12">
                          <label v-for="(brand, index) in sortInAlphabet(filters.brands)" :key="brand"
                            class="form-check-label col-12 p-0" :for="brand">
                            <input type="checkbox" name="color" :value="brand" class="checkbox" :id="brand"
                              :checked="checkedBrands.indexOf(brand) !== -1 && true"
                              @change="sendReqwest($event, 'brands')" />
                            <span class="label-text d-flex align-items-center">{{ brand }}</span>
                          </label>
                        </div>
                        <div v-else class="col-12 main-text">No available brands</div>
                      </div>
                      <!-- /Brands -->
                      <!-- Colors -->
                      <div class="accordion-body" v-if="item === 'colors'">
                        <div v-if="filters.colors?.length !== 0" class="col-12">
                          <label v-for="(color, index) in sortInAlphabet(filters.colors)" :key="color"
                            class="form-check-label col-12 p-0" :for="color">
                            <input type="checkbox" name="color" :value="color" class="checkbox" :id="color"
                              :checked="checkedColors.indexOf(color) !== -1 && true"
                              @change="sendReqwest($event, 'colors')" />
                            <span class="label-text d-flex align-items-center">{{ color }}</span>
                          </label>
                        </div>
                        <div v-else class="col-12 main-text">No available colors</div>
                      </div>
                      <!-- /Colors -->
                      <!-- Sizes -->
                      <div class="accordion-body" v-if="item === 'sizes'">
                        <div v-if="filters.sizes?.length !== 0" class="col-12">
                          <label v-for="(size, index) in filters.sizes" :key="size" class="form-check-label col-6 p-0"
                            :for="size">
                            <input type="checkbox" name="size" :value="size" class="checkbox" :id="size"
                              :checked="checkedSizes.indexOf(size) !== -1 && true"
                              @change="sendReqwest($event, 'sizes')" />
                            <span class="label-text d-flex align-items-center">{{ size }}</span>
                          </label>
                        </div>
                        <div v-else class="col-12 main-text">No available sizes</div>
                      </div>
                      <!-- /Sizes -->
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="accordion accordion-flush" id="prices">
                <div class="accordion-item">
                  <form @submit.prevent="applyFilter" style="width: 100%">
                    <fieldset class="margin-bottom-40">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#price" aria-expanded="false" aria-controls="prices">
                          <legend class="legend-filter">Price</legend>
                        </button>
                      </h2>
                      <div id="price" class="accordion-collapse collapse " aria-labelledby="headingOne"
                        data-bs-parent="#prices">
                        <div class="accordion-body">
                          <div class=" my-1">
                            <div class="mb-3">
                              <label>Min:</label>
                              <input type="number" placeholder="Min" v-model="value[0]" class="">
                            </div>
                            <div>
                              <label>Max:</label>
                              <input type="number" placeholder="Max" v-model="value[1]" class="">
                            </div>
                          </div>
                          <!--                                                <Slider v-model="value" :min="priceMin" :max="priceMax"/>-->
                          <div class="col-5 p-0 mt-3 ms-auto">
                            <button type="submit" class="dark-btn  btn-100">
                              Ok
                            </button>
                          </div>
                        </div>

                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
              <div class="row col-12 justify-content-between m-0">
                <div class="col-12 p-0">
                  <button type="reset" class="light-btn btn-100" @click="onClear">Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  End Filters form for desktop-->
        <div class="d-flex flex-wrap" style="height: fit-content; width: 100%">
          <ProductCard v-for="(product, index) in products" :key="product.id" :product="product" :auth="isAuth"
            :url="'/' + chouseCategory.at(-1)"></ProductCard>
          <div class="d-none d-lg-flex flex-grow-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Slider from '@vueform/slider/dist/slider.vue2.js';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import ProductCard from './product-card-catalog';
import { Tooltip, Collapse } from "bootstrap";
new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});
import Chips from "./chips";
export default {
  name: "products",
  components: {
    vSelect,
    Slider,
    ProductCard,
    Chips
  },
  props: ['isAuth', 'products', 'isInBrands', 'isProductsFound', 'filterCategories', 'filters', 'userFilters', 'sortSelected'],
  data() {
    return {
      title: '',
      categories: [],
      chouseCategory: '',
      findBrands: '',
      value: [
        parseInt(this.userFilters.priceMin) && parseInt(this.userFilters.priceMin) !== parseInt(this.filters.priceMin) ? parseInt(this.userFilters.priceMin) : parseInt(this.filters.priceMin),
        parseInt(this.userFilters.priceMax) && parseInt(this.userFilters.priceMax) !== parseInt(this.filters.priceMax) ? parseInt(this.userFilters.priceMax) : parseInt(this.filters.priceMax) + 1
      ],
      checkedColors: this.userFilters.color || [],
      checkedSizes: this.userFilters.size || [],
      checkedBrands: this.userFilters.brand || [],
      priceMin: parseInt(this.filters.priceMin) || 0,
      priceMax: parseInt(this.filters.priceMax) || 0,
      cloneProducts: [],
      checkboxFilter: false,
      search: new URLSearchParams(location.search).get('search'),
      options: [
        { value: "", label: "Sort by..." },
        // {value: "new", label: "Newest"},
        // {value: "old", label: "Oldest"},
        { value: "priceAsc", label: "Price ascending" },
        { value: "priceDesc", label: "Price descending" },
      ],
      dropdown: null,
      Deselect: {
        render: createElement => createElement('span', [
          createElement('img', {
            attrs: {
              src: '/images/icons/cross.svg',
              alt: 'Cross',
            },
            on: {
              click: () => {
                this.dropdown.value = '';
                this.applyFilter();
              }
            }
          }),
        ]),
      },
    }
  },
  mounted() {
    //Fixed filters on scroll
    const el = document.querySelector('.js-sticky-container');
    const container = el.parentElement;
    const params = {
      el,
      cardsBlock: el.nextElementSibling,
      container,
      elWidth: el.offsetWidth,
      elTop: el.getBoundingClientRect().top - document.body.getBoundingClientRect().top,
      containerBottom: Math.ceil(container.getBoundingClientRect().top - document.body.getBoundingClientRect().top + container.offsetHeight - el.offsetHeight),
    }

    this.updateSizeFilter();
    this.getCategoryList();
    this.showChousenCategory();
    this.filters = {
      categories: this.filterCategories,
      ...this.filters
    }
    this.dropdown = this.options.find(({ value }) => value == this.userFilters.sort) ?? this.options[0];
    this.setTitle();
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.setStickyToEl);
  },
  computed: {

  },
  methods: {
    setTitle() {
      if (this.chouseCategory.length <= 4) {
        return this.title
      } else {
        const activeCategory = this.categories.find(({ slug }) => this.chouseCategory.slice(-2).includes(slug));
        const { childCategories } = activeCategory;
        if (childCategories.length > 0) {
          const activeChildCategory = childCategories.find(({ slug }) => slug === this.chouseCategory[this.chouseCategory.length - 1]);
          this.title = activeChildCategory?.name;
        }
      }
    },
    // setStickyToEl({ el, cardsBlock, container, elWidth, elTop, containerBottom }) {
    //   const style = getComputedStyle(el)
    //   const display = style.display
    //   if (display !== 'none') {
    //     if (document.documentElement.scrollTop > elTop && document.documentElement.scrollTop < containerBottom) {

    //       el.style.position = 'fixed';
    //       container.style.position = 'static';
    //       el.style.top = '0px';
    //       el.style.left = 'auto';
    //       el.style.bottom = 'auto';
    //       cardsBlock.style.marginLeft = elWidth + 'px';
    //     }
    //     else if (document.documentElement.scrollTop > elTop && document.documentElement.scrollTop >= containerBottom) {

    //       el.style.position = 'absolute';
    //       container.style.position = 'relative'
    //       el.style.top = 'auto';
    //       el.style.bottom = '0px';
    //       el.style.left = 'auto';
    //       cardsBlock.style.marginLeft = elWidth + 'px';
    //     }

    //     else {

    //       el.style.position = 'static';
    //       container.style.position = 'static';
    //       el.style.top = 'auto';
    //       el.style.left = 'auto';
    //       el.style.bottom = 'auto'
    //       cardsBlock.style.marginLeft = '0px';
    //     }
    //   }
    // },
    sortInAlphabet(arr) {
      let newArr = arr.map((el) => el.toLowerCase());
      newArr.sort();
      return newArr;
    },
    deleteAllChip() {
      this.checkedSizes = [];
      this.checkedColors = [];
      this.checkedBrands = [];
      this.value = [parseInt(this.filters.priceMin), parseInt(this.filters.priceMax)];
      this.applyFilter();

    },
    sendReqwest(e, group) {

      switch (group) {
        case 'colors':
          if (this.checkedColors.indexOf(e.target.value) === -1) {
            this.checkedColors = [...this.checkedColors, e.target.value]
            this.applyFilter();
          } else {
            this.checkedColors.splice(this.checkedColors.indexOf(e.target.value), 1);
            this.applyFilter();
          }
          break;
        case 'sizes':
          if (this.checkedSizes.indexOf(e.target.value) === -1) {
            this.checkedSizes = [...this.checkedSizes, e.target.value]
            this.applyFilter();
          } else {
            this.checkedSizes.splice(this.checkedSizes.indexOf(e.target.value), 1);
            this.applyFilter();
          }
          break;
        case 'brands':
          if (this.checkedBrands.indexOf(e.target.value) === -1) {
            this.checkedBrands = [...this.checkedBrands, e.target.value]
            this.applyFilter();
          } else {
            this.checkedBrands.splice(this.checkedBrands.indexOf(e.target.value), 1);
            this.applyFilter();
          }
          break;
        default:
      }
    },
    deleteChip(id, group) {
      switch (group) {
        case 'colors':
          this.checkedColors = this.checkedColors.filter(e => e !== id)
          this.applyFilter();
          break;
        case 'sizes':
          this.checkedSizes = this.checkedSizes.filter(e => e !== id)
          this.applyFilter();
          break;
        case 'brands':
          this.checkedBrands = this.checkedBrands.filter(e => e !== id)
          this.applyFilter();
          break;
        case 'price':
          this.value = [parseInt(this.filters.priceMin), parseInt(this.filters.priceMax)];
          this.applyFilter();
          break;
        default:
      }
    },
    sortSizes(sizes) {
      const sizeOrder = {
        'XXXS': 1,
        'XXS': 2,
        '2XS': 3,
        'XS': 4,
        'XS/S': 5,
        'S': 6,
        'Small': 6,
        'M': 7,
        'Medium': 8,
        'Large': 9,
        'L': 10,
        'XL': 11,
        '2XL': 12,
        '3XL': 13,
        '4XL': 14,
        'XXL': 15,
        'See Below': 16,
        'Mini': 17,
        'One Size': 18,
        'NWT': 19
      };
      function compareSizes(a, b) {
        const aOrder = sizeOrder[a] || Infinity;
        const bOrder = sizeOrder[b] || Infinity;
        if (aOrder !== bOrder) {
          return aOrder - bOrder;
        } else if (isNaN(parseFloat(a))) {
          return -1;
        } else if (isNaN(parseFloat(b))) {
          return 1;
        } else {
          return parseFloat(a) - parseFloat(b);
        }
      }
      return sizes.sort(compareSizes);
    },
    updateSizeFilter() {
      this.filters.sizes = this.sortSizes(this.filters.sizes);
    },
    // sortArrayOfGradings(array) {
    //     function parseGradingOrder(grading) {
    //         let order;
    //         if (grading.includes("s")) order = -1;
    //         else if (grading.includes("m")) order = 0;
    //         else if (grading.includes("l")) order = 1;
    //         const n = Number(grading.match(/\d+(?!X)/));
    //         const numXes = grading.match(/x*/)[0].length;
    //         const mul = n ? n : numXes + 1;
    //         return order * mul;
    //     }
    //
    //     const stringArray = array.filter((el) => isNaN(el));
    //     const numberArray = array.filter((el) => !isNaN(el));
    //     const sortedStringArray = stringArray.sort((a, b) => {
    //         let aOrder = parseGradingOrder(a.toLowerCase());
    //         let bOrder = parseGradingOrder(b.toLowerCase());
    //         return aOrder - bOrder;
    //     });
    //     const sortedNumberArray = numberArray.sort((a, b) => a - b);
    //
    //     return [...sortedStringArray, ...sortedNumberArray];
    // },
    clickOnLink(e, param, id) {
      if (e.currentTarget !== e.target) {
        // let element = document.getElementById(id);
        // let myCollapse = Collapse.constructor(element, {
        //     toggle: false
        // });
        // myCollapse.dispose();
        window.open(param, "_self")
      }
    },
    getCategoryList() {
      const category = this.filterCategories.filter((cat) => cat.parent_id === null)
      this.categories = category.map((c) => {
        const childCategories = this.filterCategories.filter((sc) => sc.parent_id !== null && sc.parent_id === c.id)
        return { ...c, childCategories: childCategories }

      })
    },
    showChousenCategory() {
      if (this.isInBrands)
        return this.chouseCategory = []

      const url = location.href;
      if (url.indexOf('?') > 0) {
        const newUrl = url.slice(0, url.indexOf('?'))
        this.chouseCategory = newUrl.split('/');
      } else {
        this.chouseCategory = url.split('/');
      }
    },

    getIcon() {
      return '/images/';
    },

    applyFilter() {
      const redirectUrl = new URL(location.href);
      let priceMin;
      let priceMax;
      if (this.value[0] !== 0) {
        priceMin = this.value[0].toString()
      } else {
        priceMin = this.priceMin.toString()
      }
      if (this.value[0] !== 0) {
        priceMax = this.value[0].toString()
      } else {
        priceMax = this.priceMax.toString()
      }
      redirectUrl.searchParams.delete('page');
      redirectUrl.searchParams.set('brand', this.checkedBrands.join(','));
      redirectUrl.searchParams.set('size', this.checkedSizes.join(','));
      redirectUrl.searchParams.set('color', this.checkedColors.join(','));
      redirectUrl.searchParams.set('priceMin', this.value[0].toString());
      redirectUrl.searchParams.set('priceMax', this.value[1].toString());
      redirectUrl.searchParams.set('sort', this.dropdown?.value)
      location.href = redirectUrl.toString();
    },

    onClear() {
      this.checkedSizes = [];
      this.checkedColors = [];
      this.checkedBrands = [];
      this.value = [this.priceMin, this.priceMax];
      this.applyFilter();
    }
  }
}

</script>
<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss">
#app {
  overflow: unset !important;
}

.category-no-subcategory {
  padding: 16px 20px;
  background-color: transparent;
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px;
  line-height: 170%;
  color: #010101 !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: white;
  color: black;
  outline: none;
}

.filters-desktop {
  min-width: 300px !important;
}

.filter-sidebar {
  //border: 1px solid black;
  padding: 1.5rem 1rem;
  /* height: fit-content; */
  height: calc(100vh - 9rem);
  /* height: 765px; */
  overflow-y: scroll;
  position: relative;
  top: 1px;
  max-width: 300px !important;

}

.filter-sidebar::-webkit-scrollbar,
.filter-sidebar .accordion-body::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.filter-sidebar::-webkit-scrollbar-track,
.filter-sidebar .accordion-body::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.filter-sidebar::-webkit-scrollbar-thumb,
.filter-sidebar .accordion-body::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
/* Track */

.filter-sidebar:hover {
  &::-webkit-scrollbar-track {
    background: #D2D2D2FC;
  }
}

.filter-sidebar .accordion-body:hover {
  &::-webkit-scrollbar-track {
    background: #D2D2D2FC;
  }
}

.filter-sidebar:hover {
  &::-webkit-scrollbar-thumb {
    background: #888888FC;
  }
}

.filter-sidebar .accordion-body:hover {
  &::-webkit-scrollbar-thumb {
    background: #888888FC;
  }
}

.filter-sidebar .accordion-body{
  max-height: 395px;
  overflow-y: auto;
  position: relative;
}

.slider-target {
  max-width: 90%;
}

.slider-horizontal {
  height: 1px !important;
  margin-bottom: 94px;
}

.slider-connect {
  background: black;
  right: -9px;
}

.slider-handle {
  background: black;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.slider-horizontal .slider-handle {
  width: 10px;
  height: 10px;

}

.slider-horizontal .slider-tooltip-top {
  bottom: -50px;
}

.slider-tooltip {
  background-color: transparent;
  border: none;
  color: black;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

.slider-horizontal .slider-tooltip-top:before {
  border: 0.5px solid black;
  width: 38px;
}

.slider-base {
  right: -9px;
}

.legend-filter {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  text-align: left;
}

.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.label-text {
  font-family: montserrat-regular;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

.label-text::before {
  content: "";
  display: inline-flex;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 2px;
  margin-right: 15px;
  /* @include transition(border-color, null, null); */

  .checkbox:focus+&,
  .checkbox:hover+& {
    border-color: black;
  }

  .checkbox:checked+& {
    background-color: black;
    border-color: black;
    background-image: url('/images/icons/check.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.btn-close-filter {
  position: absolute;
  right: 30px;
  top: 30px;
}

.offcanvas {
  overflow-y: scroll;
  width: 606px !important;
}

.offcanvas-title {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 170%;
  text-transform: uppercase;
}

.offcanvas-header {
  padding: 50px 50px 30px 50px;

  @media screen and (max-width: 575px) {
    padding: 30px;
  }
}

.offcanvas-body {
  padding: 0 50px 50px 50px;

  @media screen and (max-width: 575px) {
    padding: 0 30px 30px 30px;
  }
}

.cross {
  width: 14px;
  height: 14px;

  &:hover {
    transform: scale(1.08);
  }
}

.btn-unstyled {
  background-color: transparent;
  border: none;
  border-radius: 100%;

  &:focus {
    box-shadow: none;
    border: none;
  }

  &:hover {
    box-shadow: none;
    border: none;
  }
}

.wrapper-dropdown-filter {
  position: absolute;
  width: 91%;
  top: 45px;

  @media (min-width: 576px) {
    width: 40%;
  }

  @media (min-width: 992px) {
    width: 300px;
    top: 85px;
  }
}

.wrapper-cross-filter {
  position: absolute;
  right: 20px;
  top: 20px;
}

.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  border-radius: 0;
  border-bottom: 1px solid #707070;
  text-transform: lowercase;
  font-variant: small-caps;
  color: black;
}

.style-chooser .vs__dropdown-toggle {
  padding: 9px 0;
}

.style-chooser .vs__dropdown-menu {
  background: #fff;
}

.style-chooser .vs__search::placeholder {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: black;
}

.style-chooser .vs__dropdown-menu {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: black;
  padding: 10px 0px;
  top: 30px;
  width: 100%;
  border: none;
  border-radius: 2px;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #757575;
}

.vs__dropdown-option--highlight {
  background-color: #eaecef;
  color: black;
}

.vs__search,
.vs__search:focus {
  margin: 0;
}

.vs__selected {
  margin: 0;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}

.active-page:hover {
  background-color: #2988c8;
}

/* @media screen and (min-width: 992px) {
  .accordion-filter-catalog:not(.collapsed) {
    position: fixed;
    width: 266px;
  }



} */

/* @media screen and (min-width: 992px) {

  .accordion-collapse.collapse.mt-56,
  .accordion-collapse.collapsing.mt-56 {
    margin-top: 56px;
  }

} */

.sort-select {
  width: 100%;

  @media screen and (min-width: 576px) {
    width: 155px;

  }

  @media screen and (min-width: 992px) {
    width: 285px;
    padding: 0 0 0 30px;

  }
}
</style>
