var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-slide-item"},[_c('div',{staticClass:"bordered"},[_c('button',{staticClass:"btn-without-style wrap-btn",on:{"click":function($event){return _vm.openProduct(_vm.product.slug)}}},[_c('div',{staticClass:"container-image js-catalog-product"},[_c('img',{staticClass:"image-card show",attrs:{"src":_vm.product.image_urls[0],"alt":"Not found Image, Please Contact Admin"}}),_vm._v(" "),(_vm.product.image_urls.length >= 2)?_c('img',{staticClass:"image-card second-image-card hide",attrs:{"src":_vm.product.image_urls[1],"alt":"Not found Image, Please Contact Admin"}}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"text"},[_c('h3',{staticClass:"margin-bottom-10"},[_vm._v(_vm._s(_vm.product.name))]),_vm._v(" "),_c('div',{staticClass:"card-wrap d-flex justify-content-between"},[_c('div',[(!_vm.cloneProduct.discount_price)?_c('p',{staticClass:"main-text-bold margin-top-10"},[_c('span',[_vm._v("$ "+_vm._s(parseFloat(_vm.cloneProduct.min_price) < 1000 ? parseFloat(_vm.cloneProduct.min_price).toFixed(2) :
              parseFloat(_vm.cloneProduct.min_price).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })))])]):(_vm.cloneProduct.offer_price)?_c('div',{staticClass:"main-text-bold mb-0"},[_c('span',{staticClass:"text-secondary text-decoration-line-through"},[_vm._v("$ "+_vm._s(parseFloat(_vm.cloneProduct.min_price) < 1000 ?
              parseFloat(_vm.cloneProduct.min_price).toFixed(2) :
              parseFloat(_vm.cloneProduct.min_price).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })))]),_vm._v(" "),_c('h2',{staticClass:"text-danger"},[_vm._v("$ "+_vm._s(parseFloat(_vm.cloneProduct.offer_price) < 1000 ?
                  parseFloat(_vm.cloneProduct.offer_price).toFixed(2) :
                  parseFloat(_vm.cloneProduct.offer_price).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })))])]):_vm._e(),_vm._v(" "),(_vm.product.discount_price)?_c('div',{staticClass:"mb-0 sell-price"},[_c('span',{staticClass:"text-secondary text-decoration-line-through"},[_vm._v("$ "+_vm._s(parseFloat(_vm.cloneProduct.min_price) < 1000 ?
              parseFloat(_vm.cloneProduct.min_price).toFixed(2) :
              parseFloat(_vm.cloneProduct.min_price).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })))]),_vm._v(" "),_c('h2',{staticClass:"text-danger"},[_vm._v("$ "+_vm._s(parseFloat(_vm.cloneProduct.discount_price) < 1000 ?
                  parseFloat(_vm.cloneProduct.discount_price).toFixed(2) :
                  parseFloat(_vm.cloneProduct.discount_price).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('div',[(!_vm.product.productInfos)?_c('a',{attrs:{"href":"javascript:void('Add to Cart')","data-bs-toggle":"tooltip","data-bs-placement":"top","title":"Add This Product to shopping bag"},on:{"click":function($event){return _vm.addToCart(_vm.product)}}},[_c('img',{staticClass:"card-action-img me-2",attrs:{"src":'/images/icons/card.svg',"alt":""}})]):_vm._e(),_vm._v(" "),(_vm.product.productInfos)?_c('a',{attrs:{"title":"Add This Product to shopping bag","role":"button"},on:{"click":_vm.openProductModal}},[_c('img',{staticClass:"card-action-img me-2",attrs:{"src":'/images/icons/card.svg',"alt":""}})]):_vm._e()]),_vm._v(" "),_c('div',[_c('a',{attrs:{"href":"javascript:void('Like')","data-bs-toggle":"tooltip","data-bs-placement":"top","title":"Like this"},on:{"click":function($event){return _vm.likeProduct(_vm.cloneProduct)}}},[_c('img',{attrs:{"src":_vm.getLikedIcons(_vm.cloneProduct),"alt":"Add to favourite items"}})])])])])])]),_vm._v(" "),_c('button',{staticClass:"card-btn button-text",on:{"click":function($event){return _vm.openProduct(_vm.product.slug)}}},[_vm._v("Show product")]),_vm._v(" "),(_vm.openModal)?_c('productModal',{attrs:{"product":_vm.product,"isDisabled":!_vm.openModal},on:{"close":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }