<template>
  <div class=" mb-4 mb-lg-0 card-container d-flex flex-column align-items-center">
    <!--        <div class="card-container d-flex flex-column align-items-center">-->
    <div class="card-box">
      <a :href="'/product/' + cloneProduct.slug + '/show' + url" class="">
        <div v-if="product.image_urls.length >= 2" class="container-image container-image--catalog js-catalog-product">
          <img :src="product.image_urls[0]" loading="lazy" decoding="async" class="image-card show" alt="Product Image">
          <img :src="product.image_urls[1]" loading="lazy" decoding="async" class="image-card second-image-card hide"
            alt="Product Image">
        </div>
        <div v-else class="container-image container-image--catalog">
          <img src="/images/global/logo/square.JPG" loading="lazy" decoding="async" class="image-card"
            alt="Not found Image, Please Contact Admin">
        </div>
      </a>
      <div class="text">
        <h3 class="margin-bottom-5">{{ cloneProduct.name }}</h3>
        <div class="card-wrap d-flex justify-content-between">
          <div>
            <p class="main-text-bold margin-top-10" v-if="!cloneProduct.discount_price">
              <span>$ {{ parseFloat(cloneProduct.min_price) < 1000 ? parseFloat(cloneProduct.min_price).toFixed(2) :
                parseFloat(cloneProduct.min_price).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) }}</span>
            </p>
            <div class="main-text-bold mb-0" v-else-if="cloneProduct.offer_price">
              <span class="text-secondary text-decoration-line-through">$ {{ parseFloat(cloneProduct.min_price) < 1000 ?
                parseFloat(cloneProduct.min_price).toFixed(2) :
                parseFloat(cloneProduct.min_price).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) }}</span>
                  <h2 class="text-danger">$ {{ parseFloat(cloneProduct.offer_price) < 1000 ?
                    parseFloat(cloneProduct.offer_price).toFixed(2) :
                    parseFloat(cloneProduct.offer_price).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) }}</h2>
            </div>
            <div class="mb-0 sell-price" v-if="product.discount_price">
              <span class="text-secondary text-decoration-line-through">$ {{ parseFloat(cloneProduct.min_price) < 1000 ?
                parseFloat(cloneProduct.min_price).toFixed(2) :
                parseFloat(cloneProduct.min_price).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) }}</span>
                  <h2 class="text-danger">$ {{ parseFloat(cloneProduct.discount_price) < 1000 ?
                    parseFloat(cloneProduct.discount_price).toFixed(2) :
                    parseFloat(cloneProduct.discount_price).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) }}</h2>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div>
              <a v-if="!product.infos.length" href="javascript:void('Add to Cart')" data-bs-toggle="tooltip"
                data-bs-placement="top" title="Add This Product to shopping bag" @click="addToCart(cloneProduct)">
                <img :src="'/images/icons/card.svg'" class="card-action-img me-2" alt="">
              </a>
              <a v-if="product.infos.length" title="Add This Product to shopping bag" role="button"
                @click="openProductModal">
                <img :src="'/images/icons/card.svg'" class="card-action-img me-2" alt="">
              </a>
            </div>
            <div>
              <a href="javascript:void('Like')" data-bs-toggle="tooltip" data-bs-placement="top" title="Like this"
                @click="likeProduct(cloneProduct)">
                <img :src="getLikedIcons(cloneProduct)" alt="Add to favourite items">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a :href="'/product/' + cloneProduct.slug + '/show' + url" class="card-btn button-text">Show product</a>
    <!--        <div class="d-none">{{ cloneProduct.product.id ?? '' }} || {{ cloneProduct.deleted_at ?? '' }}</div>-->
    <!--        </div>-->
    <productModal :product="cloneProduct" v-if="openModal" @close="closeModal" :isDisabled="!openModal">
    </productModal>
  </div>
</template>

<script>

import { Tooltip } from "bootstrap";
new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});
import productModal from "./product-modal";
export default {
  name: "product-card",
  components: {
    productModal,
  },
  props: ['product', "auth", 'isFavorite', 'url'],
  data() {
    return {
      openModal: false,
      cloneProduct: {},
    }
  },
  mounted() {
    this.cloneProduct = this.product;
  },
  methods: {
    openProductModal() {
      this.openModal = true;
      document.querySelector('[data-location]').classList.toggle('scroll');
    },
    closeModal() {
      this.openModal = false;
      document.querySelector('[data-location]').classList.toggle('scroll');
    },
    addToCart(product) {
      axios.get('/cart/add/?product_id=' + product.id + '&quantity=1' + '&price=' + product.min_price + '&discount_price=' + product.discount_price)
        .then(function (response) {
          $('.js-cart-icon-number').each(function (index, item) {
            $(item).text((parseInt($(item).text()) + 1) || 1);
          })
          window.notify(response.data.message)
        }).catch(err => {
          window.notify(err.response.data.message, 'error');
        });
    },

    likeProduct(cloneProduct) {
      if (this.auth) {
        cloneProduct.like = !cloneProduct.like
        axios.post('/action/like/' + cloneProduct.id).then(function (response) {
          window.notify(response.data.message)
        })
        if (this.isFavorite) {
          this.$emit('send', cloneProduct);
        }
      } else {
        window.notify('Please login or sign up first to like a product.', 'info')
      }
    },
    getLikedIcons(product) {
      let liked = "/images/icons/favourite-black.svg"
      let unliked = "/images/icons/favourite.svg"
      if (product.like) {
        return liked
      }
      return unliked
    },

    getIcon() {
      return '/images/';
    },
  }
}

</script>
<style src="@vueform/slider/themes/default.css"></style>
<style lang="scss">
.scroll {
  overflow: hidden;
}

.filter-sidebar {
  //border: 1px solid black;
  padding: 1.5rem 1rem;
  height: fit-content;
}

/* .filter-sidebar fieldset {
  max-height: 450px;
  overflow: auto;

} */

.slider-target {
  max-width: 90%;
}

.slider-horizontal {
  height: 1px !important;
  margin-bottom: 94px;
}

.slider-connect {
  background: black;
  right: -9px;
}

.slider-handle {
  background: black;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.slider-horizontal .slider-handle {
  width: 10px;
  height: 10px;

}

.slider-horizontal .slider-tooltip-top {
  bottom: -50px;
}

.slider-tooltip {
  background-color: transparent;
  border: none;
  color: black;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

.slider-horizontal .slider-tooltip-top:before {
  border: 0.5px solid black;
  width: 38px;
}

.slider-base {
  right: -9px;
}

.legend {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  margin-bottom: 25px;
  padding: 0;
}

.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.label-text {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

.label-text::before {
  content: "";
  display: inline-flex;
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 2px;
  margin-right: 15px;
  /* @include transition(border-color, null, null); */

  .checkbox:focus+&,
  .checkbox:hover+& {
    border-color: black;
  }

  .checkbox:checked+& {
    background-color: black;
    border-color: black;
    background-image: url('/images/icons/check.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.btn-close-filter {
  position: absolute;
  right: 30px;
  top: 30px;
}

.offcanvas {
  overflow-y: scroll;
  width: 606px !important;
}

.offcanvas-title {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 170%;
  text-transform: uppercase;
}

.offcanvas-header {
  padding: 50px 50px 30px 50px;

  @media screen and (max-width: 575px) {
    padding: 30px;
  }
}

.offcanvas-body {
  padding: 0 50px 50px 50px;

  @media screen and (max-width: 575px) {
    padding: 0 30px 30px 30px;
  }
}

.cross {
  width: 14px;
  height: 14px;

  &:hover {
    transform: scale(1.08);
  }
}

.btn-unstyled {
  background-color: transparent;
  border: none;
  border-radius: 100%;

  &:focus {
    box-shadow: none;
    border: none;
  }

  &:hover {
    box-shadow: none;
    border: none;
  }
}

.wrapper-dropdown-filter {
  position: absolute;
  width: 91%;
  top: 45px;

  @media (min-width: 576px) {
    width: 40%;
  }

  @media (min-width: 992px) {
    width: 300px;
    top: 85px;
  }
}

.wrapper-cross-filter {
  position: absolute;
  right: 20px;
  top: 20px;
}

.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  border-radius: 0;
  border-bottom: 1px solid #707070;
  text-transform: lowercase;
  font-variant: small-caps;
  color: black;
}

.style-chooser .vs__dropdown-toggle {
  padding: 9px 0;
}

.style-chooser .vs__dropdown-menu {
  background: #fff;
}

.style-chooser .vs__search::placeholder {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: black;
}

.style-chooser .vs__dropdown-menu {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: black;
  padding: 10px 0px;
  top: 30px;
  width: 100%;
  border: none;
  border-radius: 2px;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #757575;
}

.vs__dropdown-option--highlight {
  background-color: #eaecef;
  color: black;
}

.vs__search,
.vs__search:focus {
  margin: 0;
}

.vs__selected {
  margin: 0;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}

.active-page:hover {
  background-color: #2988c8;
}
</style>
