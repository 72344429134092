<template>
    <div>
        <!--        <button @click="sendTestPurchase">Test Purchase</button>-->
        <div v-if="parseCartItems.length > 0 && user.shippingAddresses.length>0" class="form-check mb-3"
             v-for="({address, city, state, postal_code, country, id}, index) in user.shippingAddresses">
            <input class="form-check-input" :value="id" v-model="shippingAddressId" checked type="radio"
                   name="flexRadioDefault">
            <label class="form-check-label">
                {{ address }}, {{ city }}, {{ state }}, {{ postal_code }}, {{ country }}
            </label>
        </div>
        <div v-if=" parseCartItems.length > 0 " ref="paypal"></div>
        <div ref="paypalApproved"></div>
    </div>
</template>

<script>
export default {
    name: 'PayPalBtn',
    props: {
        amount: {
            type: Number,
            required: true
        },
        discount: {
            type: Number,
            required: false
        },
        parseCartItems: {
            type: Array,
            required: true
        },
        user: {
            required: true
        },
        promoCode: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            shippingAddressId: this.user?.shippingAddresses?.[0]?.id,
        }
    },
    mounted() {
        if (this.parseCartItems.length > 0) {
            const script = document.createElement("script");
            script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.MIX_PAYPAL_CLIENT_ID}&disable-funding=credit,venmo`
            script.addEventListener("load", this.setLoaded);
            document.body.appendChild(script);
        }

    },
    methods: {
        // sendTestPurchase() {
        //     axios.post('/cart/purchase')
        //         .then(response => {
        //             window.notify(response.data.message);
        //         })
        //         .catch(error => {
        //             window.notify(error.response.data.message, 'error');
        //         })
        // },
        setLoaded() {
            let vm = this;

            window.paypal.Buttons({
                style: {
                    layout: 'vertical',
                    color: 'gold',
                    shape: 'rect',
                    label: 'paypal',
                    tagline: 'false'
                },
                // Order deliver only in US shipping address
                onShippingChange: function (data, actions) {
                    if (data.shipping_address.country_code !== 'US') {
                        return actions.reject();
                    }
                    return actions.resolve();
                },
                onClick: vm.checkAndCreateOrder,
                // Create order in paypal payment
                createOrder: vm.createOrder,
                // PayPal Approve request
                onApprove: vm.onApprove,
                // User do not finish payment
                onCancel: function () {
                    window.notify('Please finish payment process!', 'info')
                },
                // All errors from paypal
                onError: function (err) {
                    window.notify('Something went wrong, try again or contact the administrator', 'warning')
                }

            })
                .render(this.$refs.paypal)
        },

        checkAndCreateOrder(data, actions) {
            let vm = this;

            let productIds = vm.parseCartItems.map(({product}) => {
                return {
                    id: product.id,
                }
            })

            return fetch('/cart/check/products', {
                method: 'post',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            }).then(function(res) {
                return res.json();
            }).then(function(data) {
                if (data.updatedProducts === false) {
                    //
                } else {
                    window.notify("Sorry, some items have already been purchased, we have already removed them from your cart", "info", 5000);
                    setTimeout(function () {
                        window.location.reload();
                    }, 5000);
                    return actions.reject();
                }
            }).catch(function (err) {
                window.notify('Something went wrong, try again or contact the administrator!', 'warning')
                return actions.reject();
            });
        },

        createOrder(data, actions) {
            let vm = this;
            let {shippingAddresses} = vm.user;
            let sumAmountItems = 0;
            let items = vm.parseCartItems.map(({product, quantity, price, discount_price, free}) => {
                let actualPrice
                if(free){
                   actualPrice = 0
                } else{
                   actualPrice = discount_price ? discount_price : price;
                }

                sumAmountItems += actualPrice * quantity;
                return {
                    name: product.name,
                    quantity: quantity,
                    unit_amount: {
                        value: actualPrice,
                        currency_code: "USD"
                    },
                    description: vm.cleanDesc(product.description),
                    category: 'PHYSICAL_GOODS',

                }
            })
            if (shippingAddresses.length > 0) {
                let selectShippingAddress = shippingAddresses.find(({id}) => id === vm.shippingAddressId)
                let shipping = {
                    country_code: 'US',
                    admin_area_1: '',
                    admin_area_2: selectShippingAddress?.city,
                    address_line_1: selectShippingAddress?.address,
                    postal_code: selectShippingAddress?.postal_code,
                }
                // Set up the transaction
                return actions.order.create({
                    application_context: {
                        // shipping_preference: 'SET_PROVIDED_ADDRESS',
                    },
                    payer: {
                        name: {given_name: vm.user.first_name, surname: vm.user.last_name},
                        email_address: vm.user.email,
                        address: {
                            address_line_1: shipping.address_line_1,
                            address_line_2: '',
                            admin_area_2: shipping.admin_area_2,
                            admin_area_1: '',
                            postal_code: shipping.postal_code,
                            country_code: shipping.country_code,
                        },

                    },
                    purchase_units: [{
                        amount: {
                            currency_code: "USD",
                            value: vm.amount,
                            breakdown: {
                                item_total: {
                                    value: sumAmountItems,
                                    currency_code: 'USD',
                                },
                                discount: {
                                    currency_code: "USD",
                                    value: vm.discount,
                                }
                            }
                        },
                        items: items,

                        shipping: {
                            name: {
                                full_name: vm.user.first_name + ' ' + vm.user.last_name
                            },
                            type: 'SHIPPING',
                            address: shipping
                        },
                    }]
                });
            } else {
                // Set up the transaction
                return actions.order.create({
                    application_context: {
                        // shipping_preference: 'SET_PROVIDED_ADDRESS',
                    },
                    payer: {
                        name: {given_name: vm.user.first_name, surname: vm.user.last_name},
                        email_address: vm.user.email,
                    },
                    purchase_units: [{
                        amount: {
                            currency_code: "USD",
                            value: vm.amount,
                            breakdown: {
                                item_total: {
                                    value: sumAmountItems,
                                    currency_code: 'USD',
                                },
                                discount: {
                                    currency_code: "USD",
                                    value: vm.discount,
                                }
                            }
                        },
                        items: items,

                        shipping: {
                            name: {
                                full_name: vm.user.first_name + ' ' + vm.user.last_name,
                            },
                            type: 'SHIPPING',

                        },
                    }]
                });
            }

        },


        cleanDesc(desc) {
            return (desc.replace(/<\/?[^>]+(>|$)/g, "")).slice(0, 30) + '...'
        },


        onApprove(data, actions, resp) {
            let vm = this;
            return actions.order.capture().then((details) => {
                if (details.status === 'COMPLETED') {
                    let {shippingAddresses} = vm.user;
                    let addressFromOrder = {
                        country: details.purchase_units[0].shipping.address.country_code ? details.purchase_units[0].shipping.address.country_code : '',
                        city: details.purchase_units[0].shipping.address.admin_area_2 ? details.purchase_units[0].shipping.address.admin_area_2 : '',
                        state: details.purchase_units[0].shipping.address.admin_area_1 ? details.purchase_units[0].shipping.address.admin_area_1 : '',
                        address: details.purchase_units[0].shipping.address.address_line_1 ? details.purchase_units[0].shipping.address.address_line_1 : '',
                        phone: details.purchase_units[0].shipping.address.phone ? details.purchase_units[0].shipping.address.phone : '',
                        postal_code: details.purchase_units[0].shipping.address.postal_code ? details.purchase_units[0].shipping.address.postal_code : '',
                    }
                    let selectShippingAddress;

                    if (shippingAddresses.length > 0) {
                        selectShippingAddress = shippingAddresses.find(({id}) => id === vm.shippingAddressId);
                        selectShippingAddress = {
                            ...selectShippingAddress,
                            ...addressFromOrder
                        }
                    } else {
                        selectShippingAddress = {...addressFromOrder}
                    }

                    let data = {
                        promoCode: vm.promoCode ? vm.promoCode : '',
                        amount: vm.amount,
                        shippingAddress: selectShippingAddress,
                        paymentId: details.purchase_units[0].payments.captures[0].id,
                        paymentMethod: 'PayPal',
                    }
                    vm.$emit('setSpinner', true);
                    vm.savePurchase(data)
                } else {
                    vm.$emit('setSpinner', false);
                    window.notify(data.message, 'error')
                }
            });
        },


        savePurchase(data) {
            let vm = this;

            axios.post('/cart/purchase', data)
                .then(response => {
                    vm.$emit('approved')
                    window.notify(response.data.message);
                    const element = vm.$refs.paypalApproved;
                    element.innerHTML = '';
                    element.innerHTML = '<h3>Thank you for your payment!</h3>';
                })
                .catch(error => {
                    window.notify(error.response.data.message, 'error');
                })
                .finally(() => {
                    vm.$emit('setSpinner', false);
                })
        }

    }
}
</script>

