<template>
  <section class="section">
    <div class="container-small">
      <h2 class="section-title">Personal info</h2>
      <div class="row ">
        <form id="form-profile" :action="urlBack" class="d-block d-sm-flex col-12 p-0 mb-5">
          <div class="col-12 col-sm-6 mb-4 mb-sm-0">
            <label for="profile-first_name" class="d-block main-text">First name</label>
            <input v-on:change="update" id="profile-first_name" name="first_name" type="text" class="col-11 mb-4"
              placeholder="First name" :value="firstName">
            <label for="profile-last_name" class="d-block main-text">Last name</label>
            <input v-on:change="update" id="profile-last_name" name="last_name" type="text" class="col-11 mb-4"
              placeholder="Last name" :value="lastName">
          </div>
          <div class="col-12 col-sm-6">
            <label for="profile-email" class="d-block main-text">Email</label>
            <input v-on:change="update" id="profile-email" name="email" type="text" class="col-12 mb-4"
              placeholder="Email" :value="email">
            <label for="profile-location" class="d-block main-text mb-4 location-input">Location
              <input v-on:change="update" :value="location" type="text" placeholder="Location" id="profile-location"
                name="location" list="contries" class="col-12 ">
              <img src="/images/icons/arrow.svg" class="arrow" alt="arrow">
            </label>
            <datalist id="contries" class="col-12">
              <option value="Ukraine" class="col-12"></option>
              <option value="USA" class="col-12"></option>
              <option value="Germany" class="col-12"></option>
            </datalist>
          </div>
        </form>
        <button v-on:click="update" type="submit" class="dark-btn">Save</button>
      </div>
    </div>
  </section>
</template>

<script>

export default {
    props: {
        urlBack:{
            type: String,
        },
        firstName: {
            type: String,
            default: ' ',
        },
        lastName: {
            type: String,
            default: ' ',
        },
        email: {
            type: String,
            default: ' ',
        },
        location: {
            type: String,
            default: ' ',
        },
    },
    methods: {
        /**
         * Update profile field
         */
        update(e) {
            e.preventDefault();
            let form = $('#form-profile');
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                type: 'PUT',
                url: form.attr('action'),
                data: form.serialize(),
                success: function (response) {
                    window.notify(response.message)
                },
                error: function (xhr) {
                    window.notify(JSON.parse(xhr.responseText).message, 'error')
                }
            });
        }
    },
}
</script>

<style>

</style>
