<template>
    <div v-if="!onLoading" class="row align-items-center">
        <div class="col-12 d-flex flex-column flex-sm-row justify-content-between">
            <div class="d-flex col-12 col-sm-5">
                <div class="d-none d-sm-flex me-2 align-items-center">
                    <button class="slick-prev slick-arrow product-slider" aria-label="Prev"
                            data-bs-target="#carouselExampleDark" data-bs-slide="prev">Prev
                    </button>
                </div>
                <div id="carouselExampleDark" class="carousel carousel-dark slide col-12" data-bs-interval="9000000000"
                     data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button v-for="(image, index) in images" :key="index"
                                type="button" data-bs-target="#carouselExampleDark" :data-bs-slide-to="index"
                                :class="index === 0 ? 'active' : ''"
                                aria-current="true" :aria-label="'Slide' + index"></button>
                    </div>
                    <div class="carousel-inner">
                        <div v-for="(image, index) in images" :key="index"
                             :class="index === 0 ? 'carousel-item h-100 active' : 'carousel-item h-100'">
                            <a :id="'image-'+index" class="zoom-cursor"
                               :data-caption="apiProduct.name"
                               data-fancybox="1"
                               :href="image">
                                <img class="d-block w-100 h-100" style="object-fit: contain" :src="image" alt="Image not found"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="d-none d-sm-flex ms-2 align-items-center">
                    <button class="slick-next slick-arrow product-slider" aria-label="Next"
                            data-bs-target="#carouselExampleDark" data-bs-slide="next">Next
                    </button>
                </div>
            </div>
            <div class="col-12 col-sm-5 p-0">
                <div class="">
                    <h2 class="section-title mb-4">{{ apiProduct.name }}</h2>
                    <div class="product-description">
                        <div class="d-flex align-items-end">
                            <span class="main-text-bold me-2">Product size:</span>
                            <span class="main-text">{{apiProduct.size}}</span>
                        </div>
                        <div class="d-flex align-items-end">
                            <span class="main-text-bold me-2">Product colour:</span>
                            <span class="main-text">{{apiProduct.colour}}</span>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="main-text-bold me-2">Product description:</span>
                            <div class="main-text purchase-description">
                                <div>
                                    <div v-html="apiProduct.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                <div class="d-none">{{ apiProduct.product.id ?? '' }} || {{ apiProduct.deleted_at ?? '' }}</div>-->
                </div>

            </div>


        </div>
        <div class="col-12  margin-top-70">
<!--            <div class="d-flex bordered-section">-->
<!--                <h3 class="mb-4 pt-4">Product size:</h3>-->
<!--                <p class="main-text size-text">{{apiProduct.size}}</p>-->
<!--            </div>-->

<!--            <div class="d-flex">-->
<!--                <h3 class="mb-4 pt-4">Product colour:</h3>-->
<!--                <p class="main-text size-text">{{apiProduct.colour}}</p>-->
<!--            </div>-->

<!--            <h3 class="mb-4 pt-4">Product description:</h3>-->
<!--            <div class="main-text">-->
<!--                <div>-->
<!--                    <div v-html="apiProduct.description"></div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
    <div v-else class="text-center z-index-1">
        <h2>Loading Product...</h2>
    </div>
</template>
<script>
import vSelect from 'vue-select';
// import ShippingReturnPolicy from "./shipping-return-policy.vue";
import 'vue-select/dist/vue-select.css';

export default {
    name: "purchases-item",
    components: {
        vSelect,
        // ShippingReturnPolicy
    },
    props: ['product',],
    data() {
        return {
            Deselect: {
                render: createElement => createElement('span', [
                    createElement('img', {
                        attrs: {
                            src: '/images/icons/cross.svg'
                        },
                    }),
                ]),
            },
            isSizeValid: true,
            isColorValid: true,
            number: 1,
            dropdownSize: null,
            dropdownColor: null,
            onLoading: true,
            amount: 0,
            images: [],
            items: [
                {
                    text: 'Admin',
                    href: '#'
                },
                {
                    text: 'Manage',
                    href: '#'
                },
                {
                    text: 'Library',
                    active: true
                }
            ]
        }
    },

    computed: {
        // getPolicyTitle(){
        //     return JSON.parse(this.titlePolicy)
        // },
        // getPolicyItems(){
        //     return JSON.parse(this.policy)
        // },

        apiProduct() {
            return JSON.parse(this.product);
        },
        productDescription() {
            const descriptionElement = $.parseHTML(this.apiProduct.formatted_description)

            if (descriptionElement.nodeType === Node.TEXT_NODE) {
                return [this.apiProduct.formatted_description]
            }

            return $(descriptionElement)
                .find('.MsoNormal')
                .toArray()
                .slice(2)
                .map(e => e.innerText)
        },
        colors() {
            const response = JSON.parse(this.product);
            if (this.dropdownSize === null) {
                const allColors = response.productInfos?.map((item) => item.color);
                const colors = [...new Set(allColors)];

                return this.filterNullArray(colors);
            } else {
                const items = response.productInfos.filter((item) => item.size === this.dropdownSize);
                const type = typeof (items.color);
                if (type === 'string') {
                    var newArray = [items.color];

                    return newArray;
                }
                const colors = items?.map((item) => item.color);

                return this.filterNullArray(colors);
            }

        },
        sizes() {
            const response = JSON.parse(this.product);

            if (this.dropdownColor === null) {
                const allSize = response.productInfos?.map((item) => item.size);
                const size = [...new Set(allSize)];

                return this.filterNullArray(size);
            } else {
                const items = response.productInfos.filter((item) => item.color === this.dropdownColor);
                const type = typeof (items.size);
                if (type === 'string') {
                    var newArray = [items.size];

                    return newArray;
                }
                const size = items?.map((item) => item.size);

                return this.filterNullArray(size);
            }
        },
        quantity() {
            const response = JSON.parse(this.product);

            if (this.dropdownColor === null && this.dropdownSize === null) {
                const items = response.productInfos?.map((item) => item.count);
                const quantity = Math.min.apply(null, items)

                return quantity;
            } else if (this.dropdownColor !== null) {
                const item = response.productInfos.find((item) => item.color === this.dropdownColor);
                return Number(item.count);
            } else if (this.dropdownSize !== null) {
                const item = response.productInfos.find((item) => item.size === this.dropdownSize);
                return Number(item.count);
            } else {
                const item = response.productInfos.find((item) => item.size === this.dropdownSize && item.color === this.dropdownColor);
                return Number(item.count);
            }
        },

        materials() {
            const response = JSON.parse(this.product);
            const allMaterials = response.productInfos?.map((item) => item.material);
            const material = [...new Set(allMaterials)];
            let filterMaterial = this.filterNullArray(material);
            let stringMaterial = filterMaterial.join(', ');

            return stringMaterial === '' ? null : stringMaterial;
        },

        /**
         * Get product variant (info) by size and color.
         * If product has only one variant return it
         */
        userVariant() {
            if (this.apiProduct.productInfos.length === 1) {
                return this.apiProduct.productInfos[0];
            }

            return this.apiProduct.productInfos
                .find(({size, colour}) => size === this.dropdownSize && colour === this.dropdownColor)
        }
    },
    mounted() {
        this.onLoading = false;
        this.images = this.apiProduct.image_urls;
        this.calculateAmount();
    },

    methods: {
        increment() {
            let vm = this;
            if (vm.number < vm.quantity) {
                vm.number = vm.number + 1
            }
            vm.calculateAmount();
        },

        /**
         * Filter empty values from array
         * @param array
         * @returns array
         */
        filterNullArray(array) {
            return array.filter(function (el) {
                return el !== null && el !== "" && el !== "null";
            });
        },

        decrement() {
            let vm = this;
            if (vm.number !== 1) {
                vm.number = vm.number - 1
            }
            vm.calculateAmount();
        },
        check() {
            if (this.colors.length > 1 && this.dropdownColor === null) {
                this.isColorValid = false
                return
            } else if (this.colors.length === 1) {
                this.dropdownColor = this.colors[0]
            }

            if (this.sizes.length > 1 && this.dropdownSize === null) {
                this.isSizeValid = false;
                return;
            } else if (this.sizes.length === 1) {
                this.dropdownSize = this.sizes[0]
            }

            let request = {
                product_id: this.apiProduct.id,
                size: this.dropdownSize,
                color: this.dropdownColor,
                quantity: this.number,
                price: this.apiProduct.min_price,
            };

            window.open(
                `/cart/add?${$.param(request)}`, '_self'
            );
        },
        calculateAmount() {
            let vm = this;
            let minPrice = parseFloat(vm.number * vm.apiProduct.min_price);
            vm.amount = minPrice < 1000 ? minPrice.toFixed(2) : minPrice.toLocaleString('en-US', {minimumFractionDigits: 2});
        }
    }

}
</script>
<!-- <style src="simplelightbox/dist/simple-lightbox.min.css"></style> -->
<style lang="scss">
.answer-product{
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.slick-arrow.product-slider {
    position: initial;
}

.bordered-section {
    border-top: 1px solid #ececec;
}

.carousel-indicators {
    bottom: -70px;
}

.carousel {
    height: 500px;
    margin-bottom: 70px;
    @media screen and (min-width: 576px) {
        margin-bottom: 0;
        height: 420px;
    }
    @media screen and (min-width: 992px) {
        height: 500px;
    }
}

.carousel-inner {
    height: 500px;
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #ffffff;
    transition: background-color 0.6s ease;

    &.active {
        background-color: black;
    }
}

.carousel-indicators [data-bs-target] {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid black;
    background-clip: padding-box;
    transition: background-color 0.6s ease;
}

.carousel-indicators .active {
    background-color: black;
}

.product-description {
    border-bottom: 1px solid #ECECEC;
    margin-bottom: 20px;

    & > div {
        margin-bottom: 20px;

    }

    & ~ div {
        margin-bottom: 30px;
    }
}

button[disabled]:not(.fancybox-button) {
    border: 1px solid #DCDCDC;
    background-color: transparent;
    color: #DCDCDC;
}

.vs__search {
    padding: 0 !important;

    &::placeholder {
        color: #DCDCDC;

    }
}

.vs__selected {
    padding: 0;
}

.style-chooser .vs__dropdown-toggle {
    padding: 0;
}

.vs__selected-options {
    padding: 0 !important;
}

.vs__actions {
    padding: 0 0 0 2px !important;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.silentbox-item {
    width: 100%;

    & > img {
        width: 100%;
    }
}

.silentbox-gallery {
    & > :first-child {
        width: 100%;

        & > img {
            width: 100%;
        }
    }

    & > :not(:first-child) {
        width: 50px;

        & > img {
            width: 100%;
        }
    }
}

.size-text{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
}

.purchase-title{
    margin-left: 40px;
}

.purchase-description {
    max-width: 66%;
}
</style>

