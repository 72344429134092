<template>
  <MountingPortal mountTo="body" name="" append>
    <div slot="manual" slot-scope="{to}">
      <portal :to="to" :disabled="isDisabled">
        <transition name="modal-fade-product">
          <div class="modal-backdrop">
            <div class="modal p-4" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
              <header class="modal-header" id="modalTitle">
                <h3 class="" id="productModalLabel">{{ item.name }}</h3>
                <button aria-label="Close modal" type="button" class="btn-close" @click="close">
                  <img src="/images/icons/cross-black.png">
                </button>
              </header>
              <section class="modal-body" id="modalDescription">
                <div class="product-description">
                  <div v-if="sizes?.length" class="d-flex align-items-end">
                    <span class="main-text-bold me-3">Size:</span>
                    <span class="main-text col-7 col-md-9">
                      <span class="text-capitalize">{{ sizes }}</span>
                    </span>
                  </div>
                  <div v-if="colors?.length" class="d-flex align-items-end">
                    <span class="main-text-bold me-3">Color:</span>
                    <span class="main-text col-7 col-md-9">
                      <span>{{ colors }}</span>
                    </span>
                  </div>
                  <div class="d-flex" v-if="materials">
                    <span class="main-text-bold me-3">Material:</span>
                    <span class="main-text col-7 col-md-9">{{ materials }}</span>
                  </div>
                </div>
                <div class="d-flex flex-row align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <h3 class="me-2">Price:</h3>
                    <div>
                      <p class="main-text-bold" v-if="!amountDiscount">
                        <span>$ {{ amount }}</span>
                      </p>

                      <div class="mb-0 sell-price" v-else>
                        <span class="text-secondary text-decoration-line-through">$ {{ amount }}</span>
                        <h2 class="text-danger m-0">$ {{ amountDiscount }}</h2>
                      </div>
                    </div>
                  </div>
                  <div v-if="quantity >= 1" class="d-flex justify-content-end align-items-center p-0">
                    <button @click="decrement" class="small-btn--white counter-wrapper button-text me-3"
                      :disabled="number === 1">
                      -
                    </button>
                    <div class="align-items-center justify-content-center">{{ number }}</div>
                    <button @click="increment" class="small-btn counter-wrapper button-text ms-3"
                      :disabled="number === quantity">
                      +
                    </button>
                  </div>

                </div>
              </section>
              <footer class="modal-footer">
                <p v-if="quantity < 1">Sorry, this product is not available</p>
                <button v-if="quantity >= 1" type="button" class="small-btn button-text col-12" @click="axiosGetCart"
                  :disabled="adding">
                  Add to shopping bag
                  <img src="/images/icons/card-white.svg" alt="Shop product" class="margin-left-5" width="24" height="24">
                </button>
              </footer>
            </div>
          </div>
        </transition>
      </portal>
    </div>
  </MountingPortal>
</template>
<script>

export default {
  name: "product-modal",

  data() {
    return {
      item: {},
      number: 1,
      amount: 0,
      minPriceDiscount: null,
      adding: false,
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
    }
  },

  mounted() {
    this.item = this.product;
    if (this.item.productInfos.length > 1) {
      //
    }
    this.calculateAmount();
  },
  computed: {
    colors() {
      return this.product.productInfos[0].color;
    },

    sizes() {
      return this.product.productInfos[0].size;
    },

    quantity() {
      return this.product.productInfos[0].count;
    },

    materials() {
      return this.product.productInfos[0].material;
    }
  },
  methods: {
    close(e) {
      if (e.target.nodeName === 'IMG' || e.target.nodeName === 'BUTTON') {
        this.$emit('close');
      }
    },

    increment() {
      let vm = this;
      if (vm.number < vm.quantity) {
        vm.number = vm.number + 1
      }
      vm.calculateAmount();
    },

    decrement() {
      let vm = this;
      if (vm.number !== 1) {
        vm.number = vm.number - 1
      }
      vm.calculateAmount();
    },

    axiosGetCart() {
      this.adding = true;
      axios.get('/cart/add/?product_id=' + this.item.id + '&quantity=' + this.number + '&price=' + this.item.min_price + '&discount_price=' + this.item.discount_price + '&color=' + (this.colors ?? '') + '&size=' + (this.sizes ?? ''))
        .then(function (response) {
          $('.js-cart-icon-number').each(function (index, item) {
            $(item).text((parseInt($(item).text()) + 1) || 1);
          })
          window.notify(response.data.message);
        })
        .then(() => {
          this.$emit('close');

        })
        .catch(err => {
          window.notify(err.response.data.message, 'error');
        });
    },

    calculateAmount() {
      let vm = this;
      let minPrice = parseFloat(vm.number * vm.item.min_price);
      let minPriceDiscount = vm.item.discount_price ? parseFloat(vm.number * vm.item.discount_price) : null;

      vm.amount = minPrice < 1000 ? minPrice.toFixed(2) : minPrice.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      if (minPriceDiscount) {
        vm.amountDiscount = minPriceDiscount < 1000 ? minPriceDiscount.toFixed(2) : minPriceDiscount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.modal-fade-product-enter,
.modal-fade-product-leave-to {
  opacity: 0;
}

.modal-fade-product-enter-active,
.modal-fade-product-leave-active {
  transition: opacity .5s ease;
}

.modal-backdrop {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 575px) {
    width: 90%;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 70%;
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    width: 50%;
  }
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  text-transform: uppercase;
  color: black;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}
</style>
