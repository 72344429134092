<template>
  <div class="container-slide-item">
    <div class="bordered">
      <button class="btn-without-style wrap-btn" @click="openProduct(product.slug)">
        <div class="container-image js-catalog-product">
          <img :src="product.image_urls[0]" class="image-card show" alt="Not found Image, Please Contact Admin">
          <img :src="product.image_urls[1]" v-if="product.image_urls.length >= 2"
            class="image-card second-image-card hide" alt="Not found Image, Please Contact Admin">
        </div>
      </button>
      <div class="text">
        <h3 class="margin-bottom-10">{{ product.name }}</h3>
        <div class="card-wrap d-flex justify-content-between">
          <div>
            <p class="main-text-bold margin-top-10" v-if="!cloneProduct.discount_price">
              <span>$ {{ parseFloat(cloneProduct.min_price) < 1000 ? parseFloat(cloneProduct.min_price).toFixed(2) :
                parseFloat(cloneProduct.min_price).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) }}</span>
            </p>
            <div class="main-text-bold mb-0" v-else-if="cloneProduct.offer_price">
              <span class="text-secondary text-decoration-line-through">$ {{ parseFloat(cloneProduct.min_price) < 1000 ?
                parseFloat(cloneProduct.min_price).toFixed(2) :
                parseFloat(cloneProduct.min_price).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) }}</span>
                  <h2 class="text-danger">$ {{ parseFloat(cloneProduct.offer_price) < 1000 ?
                    parseFloat(cloneProduct.offer_price).toFixed(2) :
                    parseFloat(cloneProduct.offer_price).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) }}</h2>
            </div>
            <div class="mb-0 sell-price" v-if="product.discount_price">
              <span class="text-secondary text-decoration-line-through">$ {{ parseFloat(cloneProduct.min_price) < 1000 ?
                parseFloat(cloneProduct.min_price).toFixed(2) :
                parseFloat(cloneProduct.min_price).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) }}</span>
                  <h2 class="text-danger">$ {{ parseFloat(cloneProduct.discount_price) < 1000 ?
                    parseFloat(cloneProduct.discount_price).toFixed(2) :
                    parseFloat(cloneProduct.discount_price).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) }}</h2>
            </div>
          </div>

          <div class="d-flex justify-content-end align-items-center">
            <div>
              <a v-if="!product.productInfos" href="javascript:void('Add to Cart')" data-bs-toggle="tooltip"
                data-bs-placement="top" title="Add This Product to shopping bag" @click="addToCart(product)">
                <img :src="'/images/icons/card.svg'" class="card-action-img me-2" alt="">
              </a>
              <a v-if="product.productInfos" title="Add This Product to shopping bag" role="button"
                @click="openProductModal">
                <img :src="'/images/icons/card.svg'" class="card-action-img me-2" alt="">
              </a>
            </div>
            <div>
              <a href="javascript:void('Like')" data-bs-toggle="tooltip" data-bs-placement="top" title="Like this"
                @click="likeProduct(cloneProduct)">
                <img :src="getLikedIcons(cloneProduct)" alt="Add to favourite items">
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <button class="card-btn button-text " @click="openProduct(product.slug)">Show product</button>
    <productModal :product="product" v-if="openModal" @close="closeModal" :isDisabled="!openModal">
    </productModal>
  </div>
</template>
<script>
import productModal from "./product-modal";
import { Tooltip } from "bootstrap";
// v-if="finishLoadingCard"
// <div v-else>
//     <div className="spinner-grow text-dark" role="status">
//         <span className="visually-hidden">Loading...</span>
//     </div>
// </div>
new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});
export default {
  name: "product-card",
  components: {
    productModal,
  },

  props: ['product', 'use-in', 'loading', "auth"],
  data() {
    return {
      isAuth: JSON.parse(this.auth),
      finishLoadingCard: false,
      openModal: false,
      cloneProduct: {},
    }
  },
  mounted() {
    this.cloneProduct = this.product;
  },
  methods: {
    openProductModal() {
      this.openModal = true;
    },
    closeModal() {
      this.openModal = false;
    },
    addToCart(product) {
      axios.get('/cart/add/?product_id=' + product.id + '&quantity=1' + '&price=' + product.min_price + '&discount_price=' + product.discount_price)
        .then(function (response) {
          $('.js-cart-icon-number').each(function (index, item) {
            $(item).text(parseInt($(item).text()) + 1);
          })
          window.notify(response.data.message)
        }).catch(err => {
          window.notify(err.response.data.message, 'error');
        });
    },
    likeProduct(cloneProduct) {
      if (this.isAuth) {
        cloneProduct.like = !cloneProduct.like
        axios.post('/action/like/' + cloneProduct.id).then(function (response) {
          window.notify(response.data.message)
        })
      } else {
        window.notify('Please login or sign up first to like a product.', 'info')
      }
    },
    getLikedIcons(product) {
      let liked = "/images/icons/favourite-black.svg"
      let unliked = "/images/icons/favourite.svg"
      if (product.like) {
        return liked
      }
      return unliked
    },

    /**
     * Open Product page
     * @param product
     */
    openProduct(product) {
      window.open(
        `/product/${product}/show`
      );
    },
  },
}
</script>
<style lang="">

</style>
