$(document).ready(function () {
    $(document).on('mouseover', '.js-catalog-product', function (e) {
        e.preventDefault();
        let container = $(this);
        container.children('.image-card').removeClass('show').addClass('hide');
        container.children('.second-image-card').removeClass('hide').addClass('show');
    });

    $(document).on('mouseleave', '.js-catalog-product', function (e) {
        e.preventDefault();
        let container = $(this);
        container.children('.image-card').removeClass('hide').addClass('show');
        container.children('.second-image-card').removeClass('show').addClass('hide');
    });
});
